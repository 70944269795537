<!--
 * @Description: What's this for
 * @Autor: zhangzhang
 * @Date: 2021-05-19 09:49:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-06-02 18:58:23
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style lang="scss"></style>
