/*
 * @Description: template 类型组件对应
 * @Autor: zhangzhang
 * @Date: 2021-10-18 11:47:02
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-10-18 20:50:11
 */
export default {
  number: 'SchemaNumber',
  string: 'SchemaString',
  select: 'SchemaSelect',
  switch: 'SchemaSwitch',
  color: 'SchemaColor',
  upload: 'SchemaUpload',
  jump: 'SchemaJump',
  time: 'SchemaTime',
  cube: 'SchemaCapCube',
  goods: 'SchemaGoods',
  object: 'SchemaObject',
  array: 'SchemaArray',
};
