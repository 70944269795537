/*
 * @Author: Jin Biao
 * @Date: 2019-03-25 11:08:44
 * @Title: 此uniCloud 云函数请求
 * @Description: uniCloud专用
 */

/* eslint-disable */
// uni cloud请求
import global from '@/config/global';
import axiosCloud from 'axios';
import router from '@/router/index';
import qs from 'qs';
import { Loading, Message } from 'element-ui';

let loadingShow = {};

// 超时时间
axiosCloud.defaults.timeout = 100000;

// 添加请求拦截器  更新最新用户 KEY
axiosCloud.interceptors.request.use(
  config => {
    loadingShow = Loading.service();
    const token = localStorage.getItem('token');
    if (config.method == 'post') {
      config.data.token = token;
    } else if (config.method == 'get') {
      config.params = {
        token: token,
        ...config.params,
      };
    }
    return config;
  },
  error => {
    Message.error('发生错误');
    loadingShow.close();
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加请求拦截器  判断是否需要登录权限 以及是否登录
axiosCloud.interceptors.response.use(
  response => {
    loadingShow.close();
    if (response) {
      if (response.data.code == 30202 || response.data.code == 30203 || response.data.code == 30204) {
        // 未登录
        Message.error('登录失效，请重新登录');
        setTimeout(() => {
          router.push('/login');
        }, 1000);
      }
    }

    return response;
  },
  error => {
    loadingShow.close();
    Message.error('发生错误');
    return Promise.reject(error);
  }
);

/*普通接口模块*/

axiosCloud.defaults.baseURL = global.cloudApi;
axiosCloud.defaults.headers.post['Content-Type'] = 'application/json';

const GET = (url, data) => {
  return axiosCloud.get(url, { params: data }).then(res => res.data);
};
const POST = (url, data) => {
  return axiosCloud.post(url, data).then(res => res.data);
};

export default {
  GET,
  POST,
};
