/*
 * @Description: 商品管理api
 * @Autor: zhangzhang
 * @Date: 2021-09-22 15:51:56
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-04-12 07:41:33
 */
import request from '@/utils/request';

/**
 * api示例
 */
export const getGoodsList = async data => request({ url: 'goods/getByList', method: 'POST', data });
