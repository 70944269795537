/*
 * @Author: Jin Biao
 * @Date: 2022-04-05 11:59:12
 * @LastEditTime: 2022-06-08 16:23:42
 * @Description: 阿里云oss
 */

import Vue from 'vue';
import global from '@/config/global';
import { Loading, Message } from 'element-ui';
import http from '@/utils/cloud';
export default async function alioss(base64) {
  let loadingShow = Loading.service();
  function toBlob(urlData, fileType) {
    let bytes = window.atob(urlData);
    let n = bytes.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bytes.charCodeAt(n);
    }
    return new Blob([u8arr], { type: fileType });
  }
  //服务器阿里云参数;
  const res = await http.POST('/jw-admin', {
    module: 'settings',
    operation: 'getSystem',
  });
  const urlData = base64;
  const base64s = urlData.split(',').pop();
  const fileType = urlData
    .split(';')
    .shift()
    .split(':')
    .pop();

  // base64转blob
  const blob = toBlob(base64s, fileType);
  // 引入依赖
  const OSS = require('ali-oss');
  // 配置参数
  const client = new OSS({
    region: res.oss.region,
    accessKeyId: res.oss.accessKeyId,
    accessKeySecret: res.oss.accessKeySecret,
    bucket: res.oss.bucket,
    secure: true,
  });

  // dir文件夹命名
  let curTime = new Date().getTime();
  let dir = 'zhibai/' + curTime + 'ce.png';
  // 上传
  const result = await client.put(dir, blob);
  loadingShow.close();
  return result;
}
