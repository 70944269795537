<!--
 * @Description: What's this for
 * @Autor: zhangzhang
 * @Date: 2021-12-15 16:31:43
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-02-25 11:08:09
-->
<template>
  <!-- <img src="http://110.42.184.128:8090/img/16395570569031.png" @click="toGit" /> -->
</template>

<script>
export default {
  name: 'GitControl',
  methods: {
    toGit() {
      console.log('toGit')
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 30px;
  margin-right: 15px;
  cursor: pointer;
}
</style>
