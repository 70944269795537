<!--
 * @Description: 手机页面预览容器
 * @Autor: zhangzhang
 * @Date: 2021-05-25 14:55:42
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-02-25 11:57:58
-->
<template>
  <div class="ctn">
    <div v-show="head" class="ctn-head"></div>
    <div class="ctn-body">
      <slot></slot>
    </div>
    <div class="ctn-height-tag">
      <span>iPhone 8手机高度</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PhoneCtn',
  props: {
    head: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.ctn {
  position: relative;
  // width: 1920px; /*no*/
  // margin: 70px 200rpx 200px auto; /*no*/
  // margin-right: 100px;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
  border: 6rpx solid #3d7dff;

  .ctn-head {
    // widows: 100%; /*no*/
    // height: 64px; /*no*/
    // background-image: url('./phone-head.png');
    // background-size: cover;
  }

  .ctn-body {
    min-height: 1000px; /*no*/
    background: #fff;
  }

  .ctn-height-tag {
    position: absolute;
    top: 650px; /*no*/
    left: -130px; /*no*/
    height: 17px; /*no*/
    width: 130px; /*no*/
    border-bottom: 1px solid #dedede;
    color: #a2a2a2;
    text-align: left;

    span {
      font-size: 12px; /*no*/
    }
  }
}
</style>
