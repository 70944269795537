/*
 * @Description: What's this for
 * @Autor: zhangzhang
 * @Date: 2022-02-11 10:08:57
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-04 17:18:10
 */
console.log(process.env.NODE_ENV, "当前编译环境");

export default {
  //unicloud api请求地址
  // cloudApi: "https://fc-mp-40dcf3d4-cf66-4fc5-bc1a-f9417b457240.next.bspapp.com/http", //zhibaiqiye
  // cloudApi: 'https://fc-mp-12a979f4-64b0-4f3d-89c1-6a06b34d3eb9.next.bspapp.com/http', //欧罗德卡
  // cloudApi: 'https://fc-mp-56e15e7e-7bde-4491-a630-c85a26027437.next.bspapp.com/http', //济文科技
  // cloudApi: 'https://fc-mp-b4fdddbf-e2a3-4c2c-beee-52865662f37c.next.bspapp.com/http', //Vcome客户
  // cloudApi: "https://fc-mp-2504c89c-64c5-4265-925d-4e19b6236903.next.bspapp.com/http", //G客户
  cloudApi:
    "https://fc-mp-32e6e82d-5a24-4665-a54a-7eb2f0213bdc.next.bspapp.com/http", //Geryi客户

  //外部接口，java php用api请求地址（未使用不需要设置）
  baseApi: "http://www.xxxxxxx.cn/api/",

  // 是否演示环境
  demo: false,
};
