/*
 * @Description: What's this for
 * @Autor: zhangzhang
 * @Date: 2021-05-19 09:49:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-02 21:12:30
 */
import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/login"),
  },
  {
    path: "/managet",
    name: "managet",
    component: () => import("@/pages/managet"),
  },
  {
    path: "/schema",
    name: "schema",
    component: () => import("@/pages/schema"),
  },
  {
    path: "/choice",
    name: "choice",
    component: () => import("@/pages/choice"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/pages/register"),
  },
  // 用户端
  {
    path: "/mall",
    name: "mall",
    component: () => import("@/pages/mall"),
    redirect: "/mall/pages-manage",
    children: [
      {
        path: "pages-manage",
        name: "pages-manage",
        meta: {
          title: "管理员管理",
          access: ["admin"],
        },
        component: () => import("@/pages/mall/pages-manage"),
      },
      {
        path: "page-build",
        name: "page-build",
        meta: {
          title: "管理员管理2",
          access: ["admin2"],
        },
        component: () => import("@/pages/mall/page-build"),
      },
      {
        path: "goods",
        name: "goods",
        component: () => import("@/pages/mall/goods"),
        redirect: "/mall/goods/goods-manager",
        children: [
          {
            path: "goods-manager",
            name: "goods-manager",
            component: () => import("@/pages/mall/goods/goods-manager/index"),
          },
          {
            path: "goods-edit",
            name: "goods-edit",
            component: () => import("@/pages/mall/goods/goods-manager/edit"),
          },
          {
            path: "group-manager",
            name: "group-manager",
            component: () => import("@/pages/mall/goods/group-manager/index"),
          },
        ],
      },
      {
        path: "store",
        name: "store",
        component: () => import("@/pages/mall/store"),
        redirect: "/mall/store/navigation-tpl",
        children: [
          {
            path: "navigation-tpl",
            name: "navigation-tpl",
            component: () => import("@/pages/mall/store/navigation-tpl"),
          },
          {
            path: "search-tpl",
            name: "search-tpl",
            component: () => import("@/pages/mall/store/search-tpl"),
          },
          {
            path: "category-tpl",
            name: "category-tpl",
            component: () => import("@/pages/mall/store/category-tpl"),
          },
          {
            path: "bg-color",
            name: "bg-color",
            component: () => import("@/pages/mall/store/bg-color"),
          },
          {
            path: "list-tpl",
            name: "list-tpl",
            component: () => import("@/pages/mall/store/list-tpl"),
          },
          {
            path: "open-screen",
            name: "open-screen",
            component: () => import("@/pages/mall/store/open-screen"),
          },
        ],
      },
      {
        path: "model-manage",
        name: "model-manage",
        component: () => import("@/pages/mall/model-manage"),
      },
      // {
      //   path: "system",
      //   name: "system",
      //   component: () => import("@/pages/mall/admin"),
      //   redirect: "/mall/system/system",
      //   children: [
      //     {
      //       path: "admin",
      //       name: "admin",
      //       component: () => import("@/pages/mall/admin/admin"),
      //     },
      //     {
      //       path: "roles",
      //       name: "roles",
      //       component: () => import("@/pages/mall/admin/roles"),
      //     },
      //     {
      //       path: "system",
      //       name: "system",
      //       component: () => import("@/pages/mall/admin/system"),
      //     },
      //   ],
      // },
      {
        path: "commodity",
        name: "commodity",
        component: () => import("@/pages/mall/commodity"),
        redirect: "/mall/commodity/product-category",
        children: [
          {
            path: "product",
            name: "product",
            component: () => import("@/pages/mall/commodity/product/product"),
          },
          {
            path: "product-category",
            name: "product-category",
            component: () => import("@/pages/mall/commodity/product/category"),
          },
        ],
      },

      {
        path: "user",
        name: "user",
        component: () => import("@/pages/mall/user"),
        redirect: "/user/feedback",
        children: [
          {
            path: "userList",
            name: "userList",
            component: () => import("@/pages/mall/user/user"),
          },
          {
            path: "serviceUser",
            name: "serviceUser",
            component: () => import("@/pages/mall/user/serviceUser"),
          },

          {
            path: "feedback",
            name: "feedback",
            component: () => import("@/pages/mall/user/feedback"),
          },
          {
            path: "vipList",
            name: "vipList",
            component: () => import("@/pages/mall/user/vipList"),
          },
          {
            path: "sellerList",
            name: "sellerList",
            component: () => import("@/pages/mall/user/sellerList"),
          },
        ],
      },

      {
        path: "article",
        name: "article",
        component: () => import("@/pages/mall/article"),
        redirect: "/mall/article/category",
        children: [
          {
            path: "article",
            name: "article",
            component: () => import("@/pages/mall/article/article"),
          },
          {
            path: "category",
            name: "category",
            component: () => import("@/pages/mall/article/category"),
          },
        ],
      },
      {
        path: "project",
        name: "project",
        component: () => import("@/pages/mall/project"),
        redirect: "/mall/project/category",
        children: [
          {
            path: "project",
            name: "project",
            component: () => import("@/pages/mall/project/project"),
          },
          {
            path: "category",
            name: "category",
            component: () => import("@/pages/mall/project/category"),
          },
		  {
		    path: "label",
		    name: "label",
		    component: () => import("@/pages/mall/project/label"),
		  },
        ],
      },
      {
        path: "service",
        name: "service",
        component: () => import("@/pages/mall/service"),
        redirect: "/mall/service/category",
        children: [
          {
            path: "service",
            name: "service",
            component: () => import("@/pages/mall/service/service"),
          },
          {
            path: "category",
            name: "category",
            component: () => import("@/pages/mall/service/category"),
          },
        ],
      },
      {
        path: "advertise",
        name: "advertise",
        component: () => import("@/pages/mall/advertise"),
        redirect: "/mall/advertise/advertise",
        children: [
          {
            path: "advertise",
            name: "advertise",
            component: () => import("@/pages/mall/advertise/advertise"),
          },
        ],
      },
      {
        path: "case",
        name: "case",
        component: () => import("@/pages/mall/case"),
        redirect: "/mall/case/category",
        children: [
          {
            path: "case",
            name: "case",
            component: () => import("@/pages/mall/case/case"),
          },
          {
            path: "category",
            name: "category",
            component: () => import("@/pages/mall/case/category"),
          },
        ],
      },
      {
        path: "feedback",
        name: "feedback",
        component: () => import("@/pages/mall/feedback"),
        redirect: "/mall/feedback/feedbackList",
        children: [
          {
            path: "feedbackList",
            name: "feedbackList",
            component: () => import("@/pages/mall/feedback/feedback"),
          },
        ],
      },
      {
        path: "home",
        name: "home",
        component: () => import("@/pages/mall/home"),
        redirect: "/mall/home/homeData",
        children: [
          {
            path: "homeData",
            name: "homeData",
            component: () => import("@/pages/mall/home/home"),
          },
          {
            path: "domain",
            name: "domain",
            component: () => import("@/pages/mall/home/domain"),
          },
        ],
      },
      {
        path: "content",
        name: "content",
        component: () => import("@/pages/mall/content"),
        redirect: "/mall/content/notice",
        children: [
          {
            path: "notice",
            name: "notice",
            component: () => import("@/pages/mall/notice/notice"),
          },


        ],
      },
      {
        path: "help",
        name: "help",
        component: () => import("@/pages/mall/help"),
        redirect: "/mall/help/help-category",
        children: [
          {
            path: "help-article",
            name: "help-article",
            component: () => import("@/pages/mall/sale/help/article"),
          },
          {
            path: "help-category",
            name: "help-category",
            component: () => import("@/pages/mall/sale/help/category"),
          },

        ],
      },
      {
        path: "advert",
        name: "advert",
        component: () => import("@/pages/mall/advert"),
        redirect: "/mall/advert/header",
        children: [
          {
            path: "header",
            name: "header",
            component: () => import("@/pages/mall/advert/header"),
          },
          {
            path: "navigator",
            name: "navigator",
            component: () => import("@/pages/mall/advert/navigator"),
          },
          {
            path: "advert-add",
            name: "advert-add",
            component: () => import("@/pages/mall/advert/advert"),
          },

        ],
      },

      {
        path: "message",
        name: "message",
        component: () => import("@/pages/mall/message"),
        redirect: "/mall/message/sale-feedback",
        children: [
          {
            path: "sale-feedback",
            name: "sale-feedback",
            component: () => import("@/pages/mall/sale/feedback"),
          },
        ],
      },
      {
        path: "sale",
        name: "sale",
        component: () => import("@/pages/mall/sale"),
        redirect: "/mall/sale/sale-category",
        children: [
          {
            path: "sale-article",
            name: "sale-article",
            component: () => import("@/pages/mall/sale/article"),
          },
          {
            path: "sale-category",
            name: "sale-category",
            component: () => import("@/pages/mall/sale/category"),
          },


        ],
      },
      {
        path: "video",
        name: "video",
        component: () => import("@/pages/mall/video"),
        redirect: "/mall/video/video-category",
        children: [
          {
            path: "video-article",
            name: "video-article",
            component: () => import("@/pages/mall/video/article"),
          },
          {
            path: "video-category",
            name: "video-category",
            component: () => import("@/pages/mall/video/category"),
          },
          {
            path: "video-password",
            name: "video-password",
            component: () => import("@/pages/mall/video/password"),
          },
          {
            path: "video-topic",
            name: "video-topic",
            component: () => import("@/pages/mall/video/topic"),
          },
        ],
      },
      {
        path: "configure",
        name: "configure",
        component: () => import("@/pages/mall/configure"),
        redirect: "/mall/configure/contact",
        children: [
          {
            path: "contact",
            name: "contact",
            component: () => import("@/pages/mall/team/company/contact"),
          },
          {
            path: "copyright",
            name: "copyright",
            component: () => import("@/pages/mall/team/copyright/copyright"),
          },
          {
            path: "category",
            name: "category",
            component: () => import("@/pages/mall/team/copyright/category"),
          },
        ]
      },
      {
        path: "team",
        name: "team",
        component: () => import("@/pages/mall/team"),
        redirect: "/mall/team/introduce",
        children: [
          {
            path: "team-list",
            name: "team-list",
            component: () => import("@/pages/mall/team/team"),
          },
          {
            path: "team-manage",
            name: "team-manage",
            component: () => import("@/pages/mall/team/team-manage"),
          },
          {
            path: "introduce",
            name: "introduce",
            component: () => import("@/pages/mall/team/company/introduce"),
          },

          {
            path: "culture",
            name: "culture",
            component: () => import("@/pages/mall/team/company/culture"),
          },
          {
            path: "history",
            name: "history",
            component: () => import("@/pages/mall/team/company/history"),
          },
          {
            path: "honor",
            name: "honor",
            component: () => import("@/pages/mall/team/company/honor"),
          },
          {
            path: "users",
            name: "users",
            component: () => import("@/pages/mall/advert/users"),
          },
          {
            path: "honorImg",
            name: "honorImg",
            component: () => import("@/pages/mall/team/honorImg"),
          },
          {
            path: "honorImg-manage",
            name: "honorImg-manage",
            component: () => import("@/pages/mall/team/honorImg-manage"),
          },
        ],
      },
    ],
  },
  // 管理端
  {
    path: "/admin",
    name: "admin",
    component: () => import("@/pages/admin"),
    redirect: "/admin/home",
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("@/pages/admin/home"),
        redirect: "/admin/home/data",
        children: [
          {
            path: "data",
            name: "data",
            component: () => import("@/pages/admin/home/data"),
          },
          {
            path: "domain",
            name: "domain",
            component: () => import("@/pages/admin/home/domain"),
          },

        ]
      },
      {
        path: "user",
        name: "user",
        component: () => import("@/pages/admin/user"),
        redirect: "/admin/user/userList",
        children: [
          {
            path: "userList",
            name: "userList",
            component: () => import("@/pages/admin/user/list"),
          },
          {
            path: "normal",
            name: "normal",
            component: () => import("@/pages/admin/user/list"),
          },
          {
            path: "failed",
            name: "failed",
            component: () => import("@/pages/admin/user/list"),
          },
        ]
      },
      {
        path: "project",
        name: "project",
        component: () => import("@/pages/admin/project"),
        redirect: "/admin/project/projects",
        children: [
          {
            path: "projects",
            name: "projects",
            component: () => import("@/pages/admin/project/projects"),
          },
          {
            path: "backstage",
            name: "backstage",
            component: () => import("@/pages/admin/project/projects"),
          },
          {
            path: "automatic",
            name: "automatic",
            component: () => import("@/pages/admin/project/projects"),
          },
          {
            path: "models",
            name: "models",
            component: () => import("@/pages/admin/project/models"),
          }
        ]
      },
      {
        path: "website",
        name: "website",
        component: () => import("@/pages/admin/website"),
        redirect: "/admin/website/webHome",
        children: [
          {
            path: "webHome",
            name: "webHome",
            component: () => import("@/pages/admin/website/webHome"),
          },
        ]
      },
      {
        path: "agent",
        name: "agent",
        component: () => import("@/pages/admin/agent"),
        redirect: "/admin/agent/agents",
        children: [
          {
            path: "agents",
            name: "agents",
            component: () => import("@/pages/admin/agent/agents"),
          },
          {
            path: "normal",
            name: "normal",
            component: () => import("@/pages/admin/agent/agents"),
          },
          {
            path: "disable",
            name: "disable",
            component: () => import("@/pages/admin/agent/agents"),
          },
        ]
      },
      {
        path: "money",
        name: "money",
        component: () => import("@/pages/admin/money"),
        redirect: "/admin/money/order",
        children: [
          {
            path: "order",
            name: "order",
            component: () => import("@/pages/admin/money/order"),
          },
          {
            path: "recharge",
            name: "recharge",
            component: () => import("@/pages/admin/money/recharge"),
          },
        ]
      },
      {
        path: "system",
        name: "system",
        component: () => import("@/pages/admin/system"),
        redirect: "/admin/system/system",
        children: [
          {
            path: "contact",
            name: "contact",
            component: () => import("@/pages/admin/system/contact"),
          },
          {
            path: "admin",
            name: "admin",
            component: () => import("@/pages/mall/admin/admin"),
          },
          {
            path: "roles",
            name: "roles",
            component: () => import("@/pages/mall/admin/roles"),
          },
          {
            path: "system",
            name: "system",
            component: () => import("@/pages/mall/admin/system"),
          },
          {
            path: "explain",
            name: "explain",
            component: () => import("@/pages/admin/system/explain"),
          },
          {
            path: "wxInfo",
            name: "wxInfo",
            component: () => import("@/pages/admin/system/wxInfo"),
          },
        ]
      },
      {
        path: "upgrade",
        name: "upgrade",
        component: () => import("@/pages/admin/upgrade"),
        redirect: "/admin/upgrade/license",
        children: [
          {
            path: "license",
            name: "license",
            component: () => import("@/pages/admin/upgrade/license"),
          },
          {
            path: "upgrade",
            name: "upgrade",
            component: () => import("@/pages/admin/upgrade/upgrade"),
          },
          {
            path: "repair",
            name: "repair",
            component: () => import("@/pages/admin/upgrade/repair"),
          },
          {
            path: "journal",
            name: "journal",
            component: () => import("@/pages/admin/upgrade/journal"),
          }
        ],
      },
    ]
  }
];

const router = new VueRouter({
  linkActiveClass: "active",
  base: process.env.BASE_URL,
  routes,
});

// 全局路由守卫鉴权
router.beforeEach((to, from, next) => {
  if (to.name == "login" || to.name == "register") {
    next();
  } else {
    if (!store.getters.token) {
      next("/");
    } else {
      next();
    }
  }
});

export default router;
