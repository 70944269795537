<!--
 * @Description: 魔方多维布局配置
 * @Autor: zhangzhang
 * @Date: 2021-06-03 13:59:00
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-05-19 16:20:52
-->
<template>
  <div>
    <!-- 魔方行数选择 -->
    <config-item v-show="mValue.model == 'custom'" label="魔方行数">
      <el-select v-model="mValue.row" placeholder="请选择" size="small" @change="changeRow">
        <el-option v-for="key in 10" :key="key" :label="key + '行'" :value="key"></el-option>
      </el-select>
    </config-item>

    <!-- 魔方布局 -->
    <template>
      <div class="f-red f13 mb15 pl10 pr10">魔方布局</div>
      <div class="f-grey f12 mb15 pl10 pr10">移动鼠标选定布局区域大小</div>
      <div class="pl10 pl10">
        <cap-cube-layout ref="layout" :row="mValue.row" :model="mValue.model" v-model="mValue.list" @onCuurIndex="onCuurIndex"></cap-cube-layout>
      </div>
    </template>

    <!-- 魔方模板选择 -->
    <SchemaSelect class="mt15 mb20" label="模板选择" v-model="mValue.model" :data="modelOptions" @change="changeModel"></SchemaSelect>

    <div v-if="activeItem" class="nav ml10 mr10  mb20">
      <Imgpond :count="1" v-model="activeItem.image" />
    </div>
  </div>
</template>

<script>
import schemaMixin from '@/mixin/schemaMixin';
import CapCubeLayout from './CapCubeLayout';
import { initialModels, modelOptions } from './config';

export default {
  name: 'SchemaCapCube',

  mixins: [schemaMixin],

  components: {
    CapCubeLayout,
  },

  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      modelOptions,
      activeItem: {},
    };
  },

  methods: {
    async imgSuccess(base64) {
      let result = await this.$aliOSS(base64);
      if (result) {
        console.log(result.url);
        this.activeItem.image = result.url;
      } else {
        this.$message.error('图片失败');
        this.activeItem.image = '';
      }

      // const res = await http.POST('/jw-admin', {
      //   module: 'file',
      //   operation: 'addFile',
      //   base64: base64,
      // });
      // if (res) {
      //   activeItem.image = res.fileID;
      // } else {
      //   this.$message.error('图片失败');
      //   activeItem.image = '';
      // }
    },
    // 切换模型
    changeModel(model) {
      if (model) {
        // 设置模板对应行数
        let target = this.modelOptions.find(m => m.value == model);
        this.$set(this.mValue, 'row', target.row);

        // 重置模板
        this.$refs.layout.reset();

        // 设置模板对应初始数据
        if (model == 'custom') {
          this.$set(this.mValue, 'list', []);
        } else {
          this.$set(this.mValue, 'list', this._.cloneDeep(initialModels[model]));
        }
      }
    },

    // 切换行数
    changeRow() {
      this.$refs.layout.reset();
    },

    onCuurIndex(itme) {
      this.activeItem = itme;
    },
  },
};
</script>
