/*
 * @Description: What's this for
 * @Autor: zhangzhang
 * @Date: 2021-05-19 09:49:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-01-20 15:35:48
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import jump from '@/utils/jump';
import aliOSS from '@/utils/aliOSS';
import '@/scss/index.scss';

// 适配
import '@/utils/adapter.js';

import globalMethods from '@/utils/globalMethods';
import { getWrapStyle, getComponentStyle, getMultiBackground } from '@/utils/style';

// 全局注册组件
import '@/utils/globalRegister.js';

// 全局注册过滤器
import '@/utils/filters.js';

// element css
import 'element-ui/lib/theme-chalk/index.css';
import '@/scss/element-#82AAF1/index.css';

import draggable from 'vuedraggable';

import ElementVerify from 'element-verify';
import _ from 'lodash';
import global from '@/config/global';

import cloud from '@/utils/cloud.js';
Vue.prototype.$request = cloud;

Vue.config.productionTip = false;

Vue.prototype._ = _;

Vue.component('draggable', draggable);

// 挂载
Vue.use(globalMethods);
Vue.use(ElementVerify);

/**
 * 图片预览
 */
import 'pic-viewer/dist/style.css'
import PicViewer from 'pic-viewer'
Vue.use(PicViewer)

// 图片上传
import 'imgpond/dist/style.css';
import Imgpond from 'imgpond';
export function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    let fileResult = '';
    reader.readAsDataURL(file);
    //开始转
    reader.onload = () => {
      fileResult = reader.result;
    };
    //转失败
    reader.onerror = error => {
      reject(error);
    };
    //结束 resolve
    reader.onloadend = () => {
      resolve(fileResult);
    };
  });
}

Vue.use(Imgpond, {
  upload: (file, context) =>
    new Promise(async (resolve, reject) => {
      const base64 = await getBase64(file); // 转为base64
      const result = await aliOSS(base64);
      if (result) {
        resolve(result.url);
      } else {
        reject('上传图片失败');
      }
    }),
});

/**
 * 富文本
 */
// import useMiniMCE from '@/components/MiniMCE';
// useMiniMCE();

Vue.prototype.$aliOSS = aliOSS;
Vue.prototype.$jump = jump;
Vue.prototype.$getWrapStyle = getWrapStyle;
Vue.prototype.$getComponentStyle = getComponentStyle;
Vue.prototype.$getMultiBackground = getMultiBackground;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
