/*
 * @Description: 项目配置数据
 * @Autor: zhangzhang
 * @Date: 2021-08-31 19:57:35
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-04 10:46:23
 */

// 业务组件 固定页面集合  配置要在mall-cook-template/src/utils/globalJump.js
export const fixedPages = [
  {
    id: "000000",
    name: "首页",
    type: "fixed",
  },
];

// 导航配置
export const navigation = {
  label: "导航",
  styles: {
    background: "#fff",
  },
  list: [
    {
      id: "00001",
      icon: "icon-shop",
      text: "首页",
      jump: {
        type: "custom",
        id: "000000",
      },
    },
    {
      id: "00003",
      icon: "icon-sort",
      text: "分类",
      jump: {
        type: "fixed",
        id: "category",
      },
    },
    {
      id: "00004",
      icon: "icon-cart",
      text: "购物车",
      jump: {
        type: "fixed",
        id: "car",
      },
    },
    {
      id: "00005",
      icon: "icon-my",
      text: "我的",
      jump: {
        type: "fixed",
        id: "my",
      },
    },
  ],
};

// 商品分组配置
export const goodsGroups = [
  {
    name: "新品上架",
    type: "0001",
    level: 1,
    child: [],
  },
  {
    name: "热销排行",
    type: "0002",
    level: 1,
    child: [],
  },
];

// 商品列表模板配置
export const listTpl = {
  model: "column",
  addIcon: "cart",
};

// 商品列表模板配置
export const categoryTpl = {
  advertising: false, // 广告位
};

// 商城初始配置
export const rojectModel = {
  name: "",
  cover:
    "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhibai.jpg",
  config: {
    navigation: navigation,
    goodsGroups: goodsGroups,
    listTpl: listTpl,
    categoryTpl: categoryTpl,
    hotList: [],
    backgroundColor: "#FFFFFF",
  },
  pages: [
    {
      name: "首页",
      componentList: [
        {
          component: "McHeader",
          name: "顶部导航",
          icon: "icon-zujian",
          object: {
            object_bg: {
              color_bg: "#333333",
            },
            object_text: {
              color_text: "#ffffff",
            },
            object_right: {
              string_title: "English",
              jump_right: {},
              color_text: "#664498",
              switch_show: true,
            },
          },
          id: "1672551015XAUitC",
          height: 68,
        },
        {
          component: "McCard85",
          name: "卡片85",
          icon: "icon-zujian",
          pic: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1677656625357ce.png",
          object: {
            title1: "智摆建站系统",
            title2: "DIY一键式配置页面",
            text: "不懂设计、不懂编程，在线制作响应式网站。动动鼠标、想改就改、自由添加、支持深度个性化定制，免费建站。",
            button: "免费建站",
            jump: {},
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1678496924963ce.png",
            object_video: {
              url: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/%E6%88%90%E7%89%87.mp4",
              switch: true,
            },
            object_image: {
              image:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1678496759997ce.png",
              switch: false,
            },
          },
          id: "1678496708XJs5P9",
          height: 1150,
        },
        {
          component: "McCard106",
          name: "卡片106",
          icon: "icon-zujian",
          title: {
            title1: "价格套餐",
            title2: "有排名之后，按天计费，消费记录一目了然",
          },
          array_list: [
            {
              id: 1,
              title: "推广版套餐",
              price: "4000",
              text1: "优化关键词数：3个以上",
              text2: "搜索优化范围：国内搜索引擎",
              text3: "优化周期：1-3个月",
              text4: "",
              button: "立即咨询",
              switchTuijian: false,
              switch: false,
              jump: "",
            },
            {
              id: 2,
              title: "企业版套餐",
              price: "4500",
              text1: "优化关键词数：3-5个",
              text2: "百度PC价格：8元/天/词",
              text3: "百度移动价格：8元/天/词",
              text4: "360PC/搜狗PC价格：4元/天/词",
              button: "立即咨询",
              switchTuijian: true,
              switch: false,
              jump: "",
            },
            {
              id: 3,
              title: "旗舰版套餐",
              price: "6000",
              text1: "优化关键词数：6-10个",
              text2: "百度PC价格：6元/天/词",
              text3: "百度移动价格：6元/天/词",
              text4: "360PC/搜狗PC价格：3元/天/词",
              button: "立即咨询",
              switchTuijian: false,
              switch: false,
              jump: "",
            },
            {
              id: 4,
              title: "尊享版套餐",
              price: "20000",
              text1: "优化关键词数：21-40个",
              text2: "百度PC价格：3元/天/词",
              text3: "百度移动价格：3元/天/词",
              text4: "360PC/搜狗PC价格：1.5元/天/词",
              button: "立即咨询",
              switchTuijian: false,
              switch: false,
              jump: "",
            },
          ],
          id: "1677329926ghPvP5",
          height: 896,
        },
        {
          component: "McCard89",
          name: "卡片89",
          icon: "icon-zujian",
          object: {
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676601368064ce.png",
            title: "微信H5网站",
            text: "一键发布，微官网展示丰富课程内容",
            button: {
              text: "免费拥有",
              jump: {},
            },
          },
          id: "1677330095iESJaZ",
          height: 560,
        },
        {
          component: "McCard109",
          name: "卡片109",
          icon: "icon-zujian",
          array_list: [
            {
              id: 1,
              num: "26000",
              unit: "家",
              text: "企业的选择",
              switch: false,
            },
            {
              id: 2,
              num: "500",
              unit: "+",
              text: "精美模版供你选",
              switch: false,
            },
            {
              id: 3,
              num: "50",
              unit: "+",
              text: "覆盖行业",
              switch: false,
            },
          ],
          id: "1677329943T3oeW2",
          height: 146,
        },
        {
          component: "McCard26",
          name: "卡片26",
          icon: "icon-zujian",
          object: {
            title: "简约网络产品代理商全国火热招募",
            text: "代理简约网络，轻松掘金内容付费及小程序",
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673251342518ce.png",
            button1: {
              text: "代理商登录",
              jump: {},
              switch: true,
            },
            button2: {
              text: "申请代理商",
              jump: {},
              switch: true,
            },
          },
          id: "1677329991f3qMlJ",
          height: 274,
        },
        {
          component: "McCard119",
          name: "卡片119",
          icon: "el-icon-s-shop",
          id: "1677329970FnTRI3",
          height: 40,
        },
        {
          component: "McCard118",
          name: "卡片118",
          icon: "el-icon-s-order",
          object_title: {
            title1: "简约网络 · 新零售电商系统",
            title2: "赋能企业转型升级，打造智慧商业服务平台，扩展品牌市场",
          },
          object_types: {
            type1: {
              image:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673236522880ce.png",
              title: "商城系统",
              text: "完善的商城系统助力企业运营",
              list: [
                {
                  id: 1,
                  icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673236737949ce.png",
                  title: "全新UI",
                  text: "全新拖拽式设计，更好用户体验",
                },
                {
                  id: 2,
                  icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673236751476ce.png",
                  title: "商品管理",
                  text: "一键导入淘宝、京东产品详情，快速添加商品",
                },
                {
                  id: 3,
                  icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673236833969ce.png",
                  title: "物流查询",
                  text: "对接第三方物流接口，实时查询订单物流状态",
                },
                {
                  id: 4,
                  icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673236841762ce.png",
                  title: "高效沟通",
                  text: "商城在线客服实时沟通，锁定每个意向客户",
                },
              ],
            },
            type2: {
              image:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673236598779ce.png",
              title: "营销系统",
              text: "多种营销玩法提高流量销量",
              list: [
                {
                  id: 1,
                  icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673236959347ce.png",
                  title: "多人拼团",
                  text: "一键发起拼团，邀请用户参团",
                },
                {
                  id: 2,
                  icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673236967658ce.png",
                  title: "砍价助力",
                  text: "邀请好友砍价，增加产品曝光，快速引流",
                },
                {
                  id: 3,
                  icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673236974806ce.png",
                  title: "限时秒杀",
                  text: "倒计时秒杀，爆款商品促销吸粉",
                },
                {
                  id: 4,
                  icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673236980704ce.png",
                  title: "优惠券",
                  text: "激活沉睡用户，增加用户粘性，刺激消费",
                },
              ],
            },
            type3: {
              image:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673236611067ce.png",
              title: "分销推广",
              text: "社群营销促进粉丝快速裂变",
              list: [
                {
                  id: 1,
                  icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673237000016ce.png",
                  title: "全员分销",
                  text: "全民参与社交电商，促进用户快速增长",
                },
                {
                  id: 2,
                  icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673237008192ce.png",
                  title: "指定分销",
                  text: "后台开启分销特权，分销合伙人利益共享",
                },
                {
                  id: 3,
                  icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673237016430ce.png",
                  title: "购买分销",
                  text: "可设置指定购买什么商品获得分销权限",
                },
                {
                  id: 4,
                  icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673237023122ce.png",
                  title: "海报分享",
                  text: "自由切换海报样式，产品海报快速分享",
                },
              ],
            },
            type4: {
              image:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673236637341ce.png",
              title: "会员系统",
              text: "与会员深度互动，建立信任关系，增加复购",
              list: [
                {
                  id: 1,
                  icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673237081948ce.png",
                  title: "会员等级",
                  text: "会员等级、会员特权、会员成长体系，积分商城、会员关怀",
                },
                {
                  id: 2,
                  icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673237088993ce.png",
                  title: "会员折扣",
                  text: "会员享受不同折扣价，增加用户忠诚度",
                },
                {
                  id: 3,
                  icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673237095151ce.png",
                  title: "积分系统",
                  text: "用户消费产生积分，根据积分区分会员等级，积分抵扣现金消费",
                },
                {
                  id: 4,
                  icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673237117826ce.png",
                  title: "储值系统",
                  text: "储值赠送、微信储值、储值优惠、储值消费记录",
                },
              ],
            },
            type5: {
              image:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673236663730ce.png",
              title: "数据分析",
              text: "全方位运营数据剖析，辅助经营决策",
              list: [
                {
                  id: 1,
                  icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673237168753ce.png",
                  title: "交易数据",
                  text: "产品统计，订单统计，库存预警心中有数",
                },
                {
                  id: 2,
                  icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673237174647ce.png",
                  title: "营销数据",
                  text: "商品订单统计，帮助商家备货",
                },
                {
                  id: 3,
                  icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673237183782ce.png",
                  title: "财务数据",
                  text: "财务报表，佣金提现，充值统计一目了然",
                },
                {
                  id: 4,
                  icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673237191504ce.png",
                  title: "会员数据",
                  text: "用户统计，会员增长数据分析",
                },
              ],
            },
          },
          id: "16773299723SvSSU",
          height: 801,
        },
        {
          component: "McCard21",
          name: "卡片21",
          icon: "icon-zujian",
          title: {
            title1: "拥有这些，才是主流互联网企业的运营之道",
            title2:
              "简约网络通过网站建设到电商平台搭建， 以及全方位的技术支持服务，解决企业运营难题",
          },
          array_list: [
            {
              id: 1,
              image:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673245691591ce.png",
              title1: "大气的企业官网展示",
              title2:
                "一个好的企业官网，具备吸引力的同时，更能彰显品 牌实力，让客户更值得信赖",
              button: "搭建企业官网",
              jump: "",
              switch: true,
            },
            {
              id: 2,
              image:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673245669943ce.png",
              title1: "搭建独立电商平台",
              title2:
                "新零售、社交电商、跨境电商这些主流的电商模式 可以为企业更好卖货并拓展商业渠道",
              button: "搭建电商平台",
              jump: "",
              switch: true,
            },
            {
              id: 3,
              image:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673245646559ce.png",
              title1: "专业技术和运营团队支持",
              title2:
                "设计师、开发者、推广人员，我们都有全方位的团 队支持，为您的网站和平台保驾护航",
              button: "获取运营支持",
              jump: "",
              switch: true,
            },
          ],
          id: "1677329985AI5KT6",
          height: 706,
        },
        {
          component: "McCard20",
          name: "卡片20",
          icon: "icon-zujian",
          title: {
            title1: "性能强大、安全、稳定的 SaaS 产品",
            title2: "系统稳定高于一切，让商家经营有保障，消费者购物更放心",
            imageBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673245545167ce.png",
            jump: {
              text: "了解详情",
              url: {},
              switch: true,
            },
          },
          array_list: [
            {
              id: 1,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673245445592ce.png",
              title1: "护航店铺稳定",
              title2: "核心服务中断将获补偿",
              switch: true,
            },
            {
              id: 2,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673245457366ce.png",
              title1: "护航数据安全",
              title2: "加密、异地备份",
              switch: true,
            },
            {
              id: 3,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673245465790ce.png",
              title1: "护航消费者体验",
              title2: "防止流氓广告乱入",
              switch: true,
            },
            {
              id: 4,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673245473854ce.png",
              title1: "护航页面打开速度",
              title2: "页面打开速度仅需1秒",
              switch: true,
            },
          ],
          id: "1677329982rrWp4R",
          height: 413,
        },
        {
          component: "McCard17",
          name: "卡片17",
          icon: "icon-zujian",
          object: {
            title: {
              text: "高品质婚恋",
              switch: true,
            },
            left: {
              title: "实名认证",
              text1: "我们每一位注册用户都需要实名认证",
              text2: "杜绝骗子的出现",
              image:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673233127768ce.png",
              switch: true,
            },
            right: {
              title: "优质资源",
              text1: "为你提供并展示海量同城优秀单身人士",
              title2: "找到属于你的那一款",
              image:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673233138485ce.png",
              switch: true,
            },
          },
          id: "1677329979WZ7CS4",
          height: 1010,
        },
        {
          component: "McCard13",
          name: "卡片13",
          icon: "icon-zujian",
          object_title: {
            title1: "值得信赖的标题",
            title2: "Mapping Your Business",
          },
          images: [
            {
              normalImg:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              hoverImg:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei2.png",
            },
            {
              normalImg:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              hoverImg:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei2.png",
            },
            {
              normalImg:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              hoverImg:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei2.png",
            },
            {
              normalImg:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              hoverImg:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei2.png",
            },
            {
              normalImg:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              hoverImg:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei2.png",
            },
          ],
          object_array: {
            img1: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            img2: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            text: "描述的文字内容介绍处可以输入文字内容",
            button1: "查看详情",
            url1: "查看详情",
            button2: "立即体验",
            url2: "查看详情",
            array_list: [
              {
                title: "标题",
                summary: "文字介绍内容描述",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                title: "标题",
                summary: "文字介绍内容描述",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                title: "标题",
                summary: "文字介绍内容描述",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
            ],
          },
          id: "1677329978VSISru",
          height: 851,
        },
        {
          component: "McCard26",
          name: "卡片26",
          icon: "icon-zujian",
          object: {
            title: "简约网络产品代理商全国火热招募",
            text: "代理简约网络，轻松掘金内容付费及小程序",
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673251342518ce.png",
            button1: {
              text: "代理商登录",
              jump: {},
              switch: true,
            },
            button2: {
              text: "申请代理商",
              jump: {},
              switch: true,
            },
          },
          id: "1677330002XqO29x",
          height: 274,
        },
        {
          component: "McCard12",
          name: "卡片12",
          icon: "icon-zujian",
          object_title: {
            title1: "值得信赖的标题",
            title2: "Mapping Your Busin",
          },
          array: [
            {
              title: "标题标题",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              title: "标题标题",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              title: "标题标题",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              title: "标题标题",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              title: "标题标题",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              title: "标题标题",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              title: "标题标题",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              title: "标题标题",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              title: "标题标题",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              title: "标题标题",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              title: "标题标题",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              title: "标题标题",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
          ],
          id: "1677329976l7Jfic",
          height: 429,
        },
        {
          component: "McCard24",
          name: "卡片24",
          icon: "icon-zujian",
          title: {
            title1: "简约网络商城核心功能",
            title2:
              "致力于帮助更多的中小企业开展线上线下O2O、B2C、新零售业务，实现互联网转型",
          },
          array: [
            {
              id: 1,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673245987299ce.png",
              title: "分销拓客",
              text: "通过微信社交好友分销推广发展商城忠实粉丝",
              switch: true,
            },
            {
              id: 2,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246002422ce.png",
              title: "拼团",
              text: "邀请好友拼团，商城订单成倍提升，增加收益",
              switch: true,
            },
            {
              id: 3,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246009992ce.png",
              title: "砍价",
              text: "邀请好友砍价，增加商城曝光，获取意向客户",
              switch: true,
            },
            {
              id: 4,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246020266ce.png",
              title: "秒杀",
              text: "推出爆款产品，吸引用户下单，获取种子用户",
              switch: true,
            },
            {
              id: 5,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246029711ce.png",
              title: "优惠劵",
              text: "激活沉睡用户，有效刺激消费",
              switch: true,
            },
            {
              id: 6,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246138721ce.png",
              title: "产品属性",
              text: "自定义产品颜色，规格，价格等不同属性，操作便捷",
              switch: true,
            },
            {
              id: 7,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246145611ce.png",
              title: "在线支付",
              text: "邀请好友拼团，商城订单成倍提升，增加收益",
              switch: true,
            },
            {
              id: 8,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246163658ce.png",
              title: "积分系统",
              text: "根据积分划分会员等级，积分可付款抵扣",
              switch: true,
            },
            {
              id: 9,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246172044ce.png",
              title: "在线客服",
              text: "推出爆款产品，吸引用户下单，获取种子用户",
              switch: true,
            },
            {
              id: 10,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246154522ce.png",
              title: "订单提醒",
              text: "订单状态及时提醒，通知到用户及客服人员",
              switch: true,
            },
            {
              id: 11,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246273174ce.png",
              title: "虚拟销量",
              text: "自定义商城产品虚拟销量摆脱前期推广零销量尴尬",
              switch: true,
            },
            {
              id: 12,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246266218ce.png",
              title: "用户画像",
              text: "用户消费行为，消费偏好，消费能力归类定义",
              switch: true,
            },
            {
              id: 13,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246257865ce.png",
              title: "精准营销",
              text: "进行大数据分析，对目标客户精准营销，有的放矢",
              switch: true,
            },
            {
              id: 14,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246287263ce.png",
              title: "反馈评价",
              text: "用户对产品服务进行评价，增加商城信认度",
              switch: true,
            },
            {
              id: 15,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246280582ce.png",
              title: "物流追踪",
              text: "发货商品物流跟踪，用户可实时查询订单物流状态",
              switch: true,
            },
          ],
          id: "1677329996lZX0AN",
          height: 842.78125,
        },
        {
          component: "McCard115",
          name: "卡片115",
          icon: "icon-zujian",
          title: {
            title1: "平台介绍",
            title2: "百度旗下专业律师服务平台",
          },
          array_list: [
            {
              id: 1,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676962201762ce.png",
              title: "权威专业",
              text1: "精选优质法律回答寻最优方法",
              text2: "提供专业、高质量的律师服务",
              switch: false,
            },
            {
              id: 2,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676962235241ce.png",
              title: "精准匹配",
              text1: "40000名法律专业人士加盟",
              text2: "为您精准匹配律师",
              switch: false,
            },
            {
              id: 3,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676962223261ce.png",
              title: "快速应答",
              text1: "提供高效服务",
              text2: "工作时间内3分钟极速回复",
              switch: false,
            },
            {
              id: 4,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676962209273ce.png",
              title: "完整丰富",
              text1: "专业法规大全 支持精准索引",
              text2: "联合权威专家机构 打造公益法典",
              switch: false,
            },
          ],
          id: "1677329968VoNO9i",
          height: 763,
        },
        {
          component: "McCard111",
          name: "卡片111",
          icon: "icon-zujian",
          title: {
            title1: "三大定制套餐满足您不一样的诉求",
            title2: "根据你的需求，为你量身定制网站",
          },
          object_list: {
            object1: {
              title: "模版代建",
              text: "海量精美模版供你选择",
              price: {
                num: "3600",
                unit: "元起",
                text: "赠送价值888一年的系统使用套餐",
              },
              button: "咨询客服",
              jump: {},
              switch: false,
              type1: {
                title: "网站设计",
                array: [
                  {
                    id: 1,
                    text: "建站顾问提供网站策划方案",
                    include: true,
                    switch: false,
                  },
                  {
                    id: 2,
                    text: "专业建站师提供网站制作服务",
                    include: false,
                    switch: false,
                  },
                  {
                    id: 3,
                    text: "使用模板中内置交互效果",
                    include: false,
                    switch: false,
                  },
                  {
                    id: 4,
                    text: "网站自适应，一站顶三站（电脑/手机/iPad）",
                    include: false,
                    switch: false,
                  },
                ],
              },
              type2: {
                title: "网站运营售后",
                array: [
                  {
                    id: 1,
                    text: "协助甲方购买/解析域名及备案",
                    include: false,
                    switch: false,
                  },
                  {
                    id: 2,
                    text: "可视化管理后台",
                    include: false,
                    switch: false,
                  },
                  {
                    id: 3,
                    text: "提供在线客服咨询，协助网站日常维护",
                    include: false,
                    switch: false,
                  },
                  {
                    id: 4,
                    text: "SEO优化关键词设置",
                    include: true,
                    switch: false,
                  },
                ],
              },
            },
            object2: {
              title: "网站轻定",
              text: "根据参考风格设计制作",
              price: {
                num: "4600",
                unit: "元起",
                text: "赠送价值888一年的系统使用套餐",
              },
              button: "咨询客服",
              jump: {},
              switch: true,
              type1: {
                title: "网站设计",
                array: [
                  {
                    id: 1,
                    text: "建站顾问提供网站策划方案",
                    include: true,
                    switch: false,
                  },
                  {
                    id: 2,
                    text: "专业建站师提供网站制作服务",
                    include: false,
                    switch: false,
                  },
                  {
                    id: 3,
                    text: "使用模板中内置交互效果",
                    include: false,
                    switch: false,
                  },
                  {
                    id: 4,
                    text: "网站自适应，一站顶三站（电脑/手机/iPad）",
                    include: false,
                    switch: false,
                  },
                ],
              },
              type2: {
                title: "网站运营售后",
                array: [
                  {
                    id: 1,
                    text: "协助甲方购买/解析域名及备案",
                    include: false,
                    switch: false,
                  },
                  {
                    id: 2,
                    text: "可视化管理后台",
                    include: false,
                    switch: false,
                  },
                  {
                    id: 3,
                    text: "提供在线客服咨询，协助网站日常维护",
                    include: false,
                    switch: false,
                  },
                  {
                    id: 4,
                    text: "SEO优化关键词设置",
                    include: true,
                    switch: false,
                  },
                ],
              },
            },
            object3: {
              title: "私人定制",
              text: "专属设计师对你定制",
              price: {
                num: "8000",
                unit: "元起",
                text: "赠送价值1688一年的系统使用套餐",
              },
              button: "咨询客服",
              jump: {},
              switch: false,
              type1: {
                title: "网站设计",
                array: [
                  {
                    id: 1,
                    text: "建站顾问提供网站策划方案",
                    include: true,
                    switch: false,
                  },
                  {
                    id: 2,
                    text: "专业建站师提供网站制作服务",
                    include: false,
                    switch: false,
                  },
                  {
                    id: 3,
                    text: "使用模板中内置交互效果",
                    include: false,
                    switch: false,
                  },
                  {
                    id: 4,
                    text: "网站自适应，一站顶三站（电脑/手机/iPad）",
                    include: false,
                    switch: false,
                  },
                ],
              },
              type2: {
                title: "网站运营售后",
                array: [
                  {
                    id: 1,
                    text: "协助甲方购买/解析域名及备案",
                    include: false,
                    switch: false,
                  },
                  {
                    id: 2,
                    text: "可视化管理后台",
                    include: false,
                    switch: false,
                  },
                  {
                    id: 3,
                    text: "提供在线客服咨询，协助网站日常维护",
                    include: false,
                    switch: false,
                  },
                  {
                    id: 4,
                    text: "SEO优化关键词设置",
                    include: true,
                    switch: false,
                  },
                ],
              },
            },
          },
          id: "1677329959wZfJ6c",
          height: 1335,
        },
        {
          component: "McCard15",
          array: [
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
              content: "文本介绍内容处描述文本介绍内容处描述",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
              content: "文本介绍内容处描述文本介绍内容处描述",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
              content: "文本介绍内容处描述文本介绍内容处描述",
            },
          ],
          name: "卡片15",
          icon: "icon-zujian",
          id: "1658049001F0Ba6m",
          title: {
            title1: "值得信赖的标题",
            title2: "Mapping Your Business",
          },
          height: 611,
        },
        {
          component: "McCard32",
          name: "卡片32",
          icon: "icon-zujian",
          object: {
            title1: "提供各类手机H5活动营销页制作",
            title2:
              "通过趣味互动的H5页面，更友好的在微信等社交平台传播自己的品牌形象",
            button: {
              text: "定制这样的H5营销页",
              jump: {},
              switch: true,
            },
          },
          array_list: [
            {
              id: 1,
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673251714738ce.png",
              code: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673252604174ce.png",
              title: "长按缩小图H5营销页",
              text: "手指长按图片可以一直缩小，展现一个完整的故事图片",
            },
            {
              id: 2,
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673251731241ce.png",
              code: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673252604174ce.png",
              title: "做题测试小游戏",
              text: "通过平台设置的题目和答案，给到用户测试结果",
            },
            {
              id: 3,
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673251722628ce.png",
              code: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673252604174ce.png",
              title: "声音播报小游戏",
              text: "点击小组件播报声音，适合介绍某个活动的规则",
            },
            {
              id: 4,
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673251741812ce.png",
              code: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673252604174ce.png",
              title: "开心消消乐小游戏",
              text: "通过消消乐小游戏积分，给到用户对应的抵扣进行营销",
            },
          ],
          id: "1677330015CFAxU0",
          height: 1019,
        },
        {
          component: "McCard14",
          array_list: [
            {
              summary: "Multi-source data fusion",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              detail: "文本介绍内容文本介绍内容文本介绍内容",
              title: "标题处更新",
            },
            {
              summary: "Real-time update and analysis of city data",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              detail: "文本介绍内容文本介绍内容文本介绍内容",
              title: "标题处更新",
            },
            {
              summary: "Quickly build an urban brain interactive interface",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              detail: "文本介绍内容文本介绍内容文本介绍内容",
              title: "标题处更新",
            },
            {
              summary:
                "Trillion-level trajectory data is presented and analyzed in seconds",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              detail: "文本介绍内容文本介绍内容文本介绍内容",
              title: "标题处更新",
            },
          ],
          name: "卡片14",
          icon: "icon-zujian",
          object_title: {
            title1: "值得信赖的标题",
            title2: "Mapping Your Business",
          },
          id: "16580489935E2i9X",
          height: 550,
        },
        {
          component: "McCard31",
          name: "卡片31",
          icon: "icon-zujian",
          object: {
            img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673251507428ce.png",
            title: " 企业运营有哪些痛点？",
            button: {
              text: "立即解决",
              jump: {},
              switch: true,
            },
            array: [
              {
                id: 1,
                text1: "网站没有流量",
                text2: "新网站一般都会缺乏流量，没有价值",
              },
              {
                id: 2,
                text1: "商品卖不出去",
                text2: "流量低导致商品销售不出去，缺少展现",
              },
              {
                id: 3,
                text1: "缺少推广渠道",
                text2: "不了解互联网推广渠道，无从下手",
              },
              {
                id: 4,
                text1: "没有运营人员",
                text2: "缺乏专业的运营人员，招聘成本高",
              },
            ],
          },
          id: "1677330013ERyqs3",
          height: 546,
        },
        {
          component: "McCard49",
          name: "卡片49",
          icon: "icon-zujian",
          object: {
            title1: "一键安装快速部署",
            title2: "完善教程快速搭建私有化商城系统",
            image:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673322681219ce.png",
            list1: {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673316944389ce.png",
              title: "服务器环境配置",
              array: [
                {
                  id: 1,
                  text: "服务环境：linux/windows",
                  switch: true,
                },
                {
                  id: 2,
                  text: "web服务：Nginx/Apache/iis",
                  switch: true,
                },
                {
                  id: 3,
                  text: "PHP版本：7.1～7.4",
                  switch: true,
                },
                {
                  id: 4,
                  text: "数据库：Mysql 5.7~8.0",
                  switch: true,
                },
                {
                  id: 5,
                  text: "Redis：Redis 6.0以上",
                  switch: true,
                },
              ],
            },
            list2: {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673322690832ce.png",
              title: "服务器环境配置",
              array: [
                {
                  id: 1,
                  text: "系统自带安装程序支持一键安装",
                  switch: true,
                },
                {
                  id: 2,
                  text: "支持手动配置安装",
                  switch: true,
                },
                {
                  id: 3,
                  text: "支持docker-compose一键部署",
                  switch: true,
                },
                {
                  id: 4,
                  text: "支持宝塔环境一键安装",
                  switch: true,
                },
                {
                  id: 5,
                  text: "支持前后端分离安装",
                  switch: true,
                },
                {
                  id: 6,
                  text: "支持分布式部署安装",
                  switch: true,
                },
              ],
            },
          },
          id: "1677330034byxLd7",
          height: 661,
        },
        {
          component: "McCard43",
          name: "卡片43",
          icon: "icon-zujian",
          object: {
            title1: "满足多行业多场景应用需求",
            title2: "立足电商行业数年，懂客户，更懂营销",
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673312244894ce.png",
            array: [
              {
                id: 1,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673312148923ce.png",
                text: "直播带货",
                switch: true,
              },
              {
                id: 2,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673312162375ce.png",
                text: "社区拼团",
                switch: true,
              },
              {
                id: 3,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673312173234ce.png",
                text: "分销电商",
                switch: true,
              },
              {
                id: 4,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673312180649ce.png",
                text: "会员电商",
                switch: true,
              },
              {
                id: 5,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673312187804ce.png",
                text: "食品生鲜",
                switch: true,
              },
              {
                id: 6,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673312196071ce.png",
                text: "服装鞋帽",
                switch: true,
              },
              {
                id: 7,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673312206408ce.png",
                text: "商超百货",
                switch: true,
              },
              {
                id: 8,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673312213243ce.png",
                text: "数码家电",
                switch: true,
              },
              {
                id: 9,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673312221886ce.png",
                text: "更多行业",
                switch: true,
              },
            ],
          },
          id: "1677330030SvAML5",
          height: 353,
        },
        {
          component: "McCard40",
          name: "卡片40",
          icon: "icon-zujian",
          title: {
            title1: "CRMEB标准版 新零售社交电商系统",
            title2:
              "赋能开发者，提速企业数字化；倾力打造全开源超高性价比社交电商系统",
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673254218132ce.png",
          },
          array_list: [
            {
              id: 1,
              title: "独立部署",
              text: "私有化独立安装部署，数据更安全",
              switch: true,
            },
            {
              id: 2,
              title: "方便二开",
              text: "代码全开源，注释清晰，提供完整API接口，满足用户更多二次开发需求",
              switch: true,
            },
            {
              id: 3,
              title: "代码全开源",
              text: "一切为了开发者！坚持代码开源，为开发者赋能",
              switch: true,
            },
            {
              id: 4,
              title: "文档齐全",
              text: "开发、接口、安装、使用文档全面支持，帮助用户快速安装使用",
              switch: true,
            },
            {
              id: 5,
              title: "支持多语言",
              text: "用户可任意切换中、英、法、意、日、韩、繁等十余种语言格式",
              switch: true,
            },
            {
              id: 6,
              title: "正版授权",
              text: "官方正版授权，放心、省心、安心",
              switch: true,
            },
          ],
          id: "1677330025s1jc4F",
          height: 719,
        },
        {
          component: "McCard36",
          name: "卡片36",
          icon: "icon-zujian",
          title: {
            title1: "全网营销推广服务，快速曝光企业形象",
            title2:
              "简约网络专业的推广团队，通过竞价推广策略，快速曝光品牌形象，即时见效获取流量",
          },
          array: [
            {
              id: 1,
              title: "竞价推广开户",
              tit: "百度、360、搜狗代开户， 框架户高返点，账户托管",
              txt: "简约网络是一家互联网综合性服务提供商，我们为您提供搜索引擎竞价账户托管、信息流广告账户托管、营销策划，常规户，框架户，促进转化率提升，品牌建设，口碑营销等服务",
              button: "获取我的推广服务",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673253335076ce.png",
              jump: "",
            },
            {
              id: 2,
              title: "竞价排名占位",
              tit: "搜索引擎营销（SEM） 实时抢占排名，调整出价策略",
              txt: "根据客户推广目标，测试和制定整月地域推广计划和推广进度，控制每日预算，合理分配推广计划预算，了解媒体的新产品，根据客户行业特点及推广目标，推荐符合的推广产品，关注竞争对手的创意、展示、排名、随时做出调整",
              button: "获取我的推广服务",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673253335076ce.png",
              jump: "",
            },
            {
              id: 3,
              title: "全网链接投放",
              tit: "在各大平台投放网站链接 提升网站权重和页面索引量",
              txt: "通过在多个网络平台发布品牌介绍信息，加入网站的链接，全方位的让品牌形象得到曝光，当信息积累的越来越丰富以后，网站整体的权重和排名则可以不断的提升",
              button: "获取我的推广服务",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673253335076ce.png",
              jump: "",
            },
            {
              id: 4,
              title: "数据统计分析",
              tit: "根据每日推广情况 制作日 / 周 / 月度分析报表",
              txt: "根据网站投放的广告预算，以及我们技术人员做出的推广服务，我们会根据实际情况做出专业的报表，同时分析数据波动原因，让企业看到完善的推广方案和效果，免去后顾之忧",
              button: "获取我的推广服务",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673253335076ce.png",
              jump: "",
            },
            {
              id: 5,
              title: "营销软文编写",
              tit: "企业营销长文章编写 投放公众号，外部自媒体平台",
              txt: "为企业品牌和机构撰写优质的营销软文和新闻稿，建设品牌文章营销服务，投放到公众号，微博、头条等媒体平台，吸引更多等曝光和浏览量，同时促进SEO的收录",
              button: "获取我的推广服务",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673253335076ce.png",
              jump: "",
            },
          ],
          id: "1677330022FXFR4U",
          height: 793,
        },
        {
          component: "McCard33",
          name: "卡片33",
          icon: "icon-zujian",
          object: {
            title1: "短视频营销服务，让用户快速记住品牌形象",
            title2:
              "通过趣味的短视频吸引用户关注、在抖音、微信朋友圈等社交平台快速传播",
            array: [
              {
                id: 1,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673252188627ce.png",
              },
              {
                id: 2,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673252207501ce.png",
              },
              {
                id: 3,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673252215327ce.png",
              },
            ],
          },
          id: "1677330019eDFEtI",
          height: 484,
        },
        {
          component: "McCard28",
          name: "卡片28",
          icon: "icon-zujian",
          object: {
            title: "简约网络扶持计划",
            array: [
              {
                id: 1,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673247917556ce.png",
                title: "丰厚返利",
                text1: "产品理念培训",
                text2: "使用操作培训",
                text3: "企业文化培训",
              },
              {
                id: 2,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673248025374ce.png",
                title: "海量资源",
                text1: "产品理念培训",
                text2: "使用操作培训",
                text3: "企业文化培训",
              },
              {
                id: 3,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673247931936ce.png",
                title: "海量资源",
                text1: "产品理念培训",
                text2: "使用操作培训",
                text3: "企业文化培训",
              },
              {
                id: 4,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673247941206ce.png",
                title: "服务支持",
                text1: "产品理念培训",
                text2: "使用操作培训",
                text3: "企业文化培训",
              },
              {
                id: 5,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673248025374ce.png",
                title: "市场支持",
                text1: "产品理念培训",
                text2: "使用操作培训",
                text3: "企业文化培训",
              },
              {
                id: 6,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673247931936ce.png",
                title: "优秀产品",
                text1: "产品理念培训",
                text2: "使用操作培训",
                text3: "企业文化培训",
              },
            ],
          },
          id: "1677330011OfxlMp",
          height: 507,
        },
        {
          component: "McCard30",
          name: "卡片30",
          icon: "icon-zujian",
          object: {
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673251420243ce.png",
            title: "简约网络全网营销解决方案",
            text: "整合海量商业资源，助力企业运营，获取流量",
            button: {
              text: "获取运营方案",
              jump: {},
              switch: true,
            },
          },
          id: "1677330010DLjjwf",
          height: 500,
        },
        {
          component: "McCard27",
          name: "卡片27",
          icon: "icon-zujian",
          object: {
            title: "携手简约网络  共掘内容付费万亿市场",
            array: [
              {
                id: 1,
                title: "工具升级",
                text: "移动支付的普及和H5、小程序等新内容表现形式的发展，极大扩展了内容变现的覆盖场景",
              },
              {
                id: 2,
                title: "消费升级带动认知升级",
                text: "互联网带来的认知改变，用户开始愿意为内容变现，文化消费比例逐渐提高",
              },
              {
                id: 3,
                title: "优质内容价值凸显",
                text: "互联网免费资源泛滥，身处信息爆炸的环境之下，大量催生垂直细分领域的个性化付费内容，用户更愿意为专业深度内容付费",
              },
              {
                id: 4,
                title: "大量内容生产者加入",
                text: "继吴晓波频道、十点课堂等自媒体大号使用简约网络进行内容变现获取大量付费用户后，诸多自媒体看到了继广告、电商后的第三条变现之路，纷纷利用简约网络进行付费尝试",
              },
            ],
          },
          id: "1677330009XMZQ3h",
          height: 498,
        },
        {
          component: "McCard12",
          array: [
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
          ],
          name: "卡片12",
          icon: "icon-zujian",
          object_title: {
            title1: "值得信赖的标题",
            title2: "Mapping Your Busin",
          },
          id: "1658048982YaWxsi",
          height: 429,
        },
        {
          component: "McProductHot",
          icon: "el-icon-menu",
          name: "产品推荐",
          id: "1650971031R7njDd",
          height: 421,
        },
        {
          component: "McCard58",
          name: "卡片58",
          icon: "icon-zujian",
          object: {
            title: "软件下载",
            array: [
              {
                id: 1,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673575914599ce.png",
                text: "Windows",
                jump: "",
                switch: true,
              },
              {
                id: 2,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673575923300ce.png",
                text: "Mac",
                jump: "",
                switch: true,
              },
              {
                id: 3,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673575908016ce.png",
                text: "Android",
                jump: "",
                switch: true,
              },
              {
                id: 4,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673575934514ce.png",
                text: "ios",
                jump: "",
                switch: true,
              },
            ],
          },
          id: "1677330049HAx3cI",
          height: 452,
        },
        {
          component: "McCard57",
          name: "卡片57",
          icon: "icon-zujian",
          object: {
            title: "客户案例",
            array: [
              {
                id: 1,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673575822405ce.png",
                logo: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673575844256ce.png",
                text: "全栈信创改造，通过国家工信安全中心评测，满足金融、政企、事业单位信创改造，自主创新，安全稳定，兼容国内50余种主流软硬件厂商产品，打造全生态信创平台",
              },
              {
                id: 2,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673575822405ce.png",
                logo: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673575844256ce.png",
                text: "咪咕采用混合云方式，搭建了一套视频会议系统，业务系统私有化，音视频推拉流使用智摆的公有云服务，保证了业务的安全性以及隐私性，全球节点为会议提供跨国无延时会议，大大提高了工作效率",
              },
              {
                id: 3,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673575822405ce.png",
                logo: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673575844256ce.png",
                text: "我们为北电搭建了智摆会议教育版，此教育版针对艺术类院校特别定制，系统优化了音视频服务的清晰度和原音质，帮助北电真实还原对声音和音乐的理解以及纠正，配合课堂硬件给到艺术院校最优秀的解决方案",
              },
            ],
          },
          id: "1677330051HaOdTW",
          height: 520,
        },
        {
          component: "McCard56",
          name: "卡片56",
          icon: "icon-zujian",
          title: {
            title1: "自有硬件产品",
            title2: "智摆会议兼容市面120+硬件友商产品",
            array: [
              {
                id: 1,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673575324827ce.png",
                title: "智摆智慧屏",
                text1: "65/75/86英寸可选",
                text2: "8m拾音距离，四阵列拾音器",
                text3: "4K高清屏 + 4K高清摄像头",
                text4: "20点触控操作，白板协作高效",
              },
              {
                id: 2,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673575350929ce.png",
                title: "视频终端（标准款）",
                text1: "4K高清镜头，镜头86.6°超广角无畸变镜头",
                text2: "内置阵列麦克风，全向拾音",
                text3: "",
                text4: "",
              },
              {
                id: 3,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673575330760ce.png",
                title: "视频终端（专业款）",
                text1: "一体化设计，内置主机、4K摄像头、麦克风、扬声器",
                text2: "AI算法，具有声源定位、自动框选，最佳会议视角",
                text3: "",
                text4: "",
              },
              {
                id: 4,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673575360556ce.png",
                title: "视频终端（旗舰款）",
                text1: "一体化设计，镜头麦克风全集成",
                text2: "12倍光学变焦云台镜头，72.5°高品质超广角镜头",
                text3: "",
                text4: "",
              },
              {
                id: 5,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673575369312ce.png",
                title: "无线投屏器",
                text1: "无线投屏，无需线缆连接",
                text2: "支持电脑、手机多终端连接",
                text3: "同步无延迟，更高清",
                text4: "支持投屏/同屏双模式",
              },
              {
                id: 6,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673575343637ce.png",
                title: "智能全向麦克风",
                text1: "360°智能拾音，5m拾音",
                text2: "智能动态降噪，人声更清晰",
                text3: "5.5英寸FHD显示屏，可视交互",
                text4: "",
              },
            ],
          },
          id: "1677330047GsJWJA",
          height: 879,
        },
        {
          component: "McNewsHot",
          icon: "el-icon-menu",
          name: "新闻推荐",
          id: "1650970855gXOC3x",
          height: 411,
        },
        {
          component: "McBox6",
          icon: "el-icon-help",
          name: "特效盒6",
          id: "1652939187OOP4lF",
          height: 300,
          object: {
            title: "自定义根管锉 让根管不再难做",
          },
        },
        {
          component: "McCard3",
          icon: "el-icon-menu",
          name: "卡片3",
          id: "1652259620wBJxCx",
          object_title: {
            array_list: [
              {
                string_list: "表面三重处理工艺",
              },
              {
                string_list: "预弯性强",
              },
              {
                string_list: "抗疲劳性强",
              },
              {
                string_list: "热激活 记忆功能",
              },
              {
                string_list: "切削力强",
                id: "1652260151uWks5p",
              },
              {
                string_list: "排屑力强",
                id: "16522601660Xqf55",
              },
              {
                string_list: "进口高纯镍钛",
                id: "1652260174dqSDGZ",
              },
            ],
            string_title1: "机用根管锉PLEX 2.0",
            string_title2: "Mechanical root canal file plex 2.0",
            upload_image2:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652259950109ce.png",
            url: "/pages/index/custom/custom?pageId=1649499891FFNR",
            upload_image1:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1657939361480ce.png",
          },
          height: 731,
        },
        {
          component: "McCard59",
          name: "卡片59",
          icon: "icon-zujian",
          object: {
            title: "软件下载",
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673576022223ce.png",
            way1: {
              text: " 项目合作，留下您的联系方式",
              jump: {},
            },
            way2: {
              text: "索取行业解决方案",
              jump: {},
            },
            button: {
              text: "申请试用",
              jump: {},
            },
          },
          id: "1677330055r4YJAR",
          height: 430,
        },
        {
          component: "McCard13",
          images: [],
          object_array: {
            button2: "立即体验",
            array_list: [
              {
                summary: "降低成本，提高利润点",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/solution/logistics/icon-comprehensive.png",
                title: "成本",
              },
              {
                summary: "处理过程中，减少牙齿损耗",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/solution/logistics/icon-transaction.png",
                title: "损耗",
              },
              {
                summary: "比传统机器更高的处理机能",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/solution/logistics/icon-settlement.png",
                title: "处理能力",
              },
            ],
            url1: "/pages/index/custom/custom?pageId=1649499891FFNR",
            url2: "/pages/index/custom/custom?pageId=1649499891FFNR",
            text: "可高温高压消毒，避免交叉感染。角度可调节，适用于不同的牙位。机头小巧，不遮挡视野。",
            button1: "查看详情",
            img2: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/solution/logistics/icon-circle.png",
            img1: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652258126971ce.png",
          },
          icon: "el-icon-menu",
          name: "卡片13",
          id: "1652257764MUD5gq",
          object_title: {
            title1: "自定义无线根管预备机",
            title2: "Endo Smart",
          },
          height: 719,
        },
        {
          component: "McCard62",
          name: "卡片62",
          icon: "icon-zujian",
          object: {
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673576431034ce.png",
            array: [
              {
                id: 1,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673576473669ce.png",
                title: "212",
                text: "覆盖212个国家/地区，全球用户体验毫秒级互动",
              },
              {
                id: 2,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673576445849ce.png",
                title: "30亿",
                text: "日均通话时长达30亿分钟，跻身云通讯行业头部",
              },
              {
                id: 3,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673576440647ce.png",
                title: "100",
                text: "全方位行业解决方案，满足百余个业务场景需要",
              },
              {
                id: 4,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673576450846ce.png",
                title: "4000+",
                text: "服务客户4000家，70%泛娱乐/在线教育客户的选择",
              },
            ],
          },
          id: "1677330057nslf9r",
          height: 362,
        },
        {
          object_image: {
            array: [
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652261233420ce.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652261237965ce.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652261243201ce.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652261247944ce.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652261252152ce.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652261257328ce.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652261262166ce.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652261266850ce.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652261272953ce.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652261277520ce.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652261282334ce.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652261286127ce.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652261290350ce.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652261294196ce.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652261298107ce.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652261302608ce.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652261306513ce.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652261311231ce.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652261314974ce.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652261318502ce.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652261321686ce.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652261327204ce.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/home/lbs-2.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/home/lbs-3.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/home/lbs-4.png",
              },
            ],
          },
          component: "McCard5",
          icon: "el-icon-menu",
          name: "卡片5",
          id: "1652261223cQwOmW",
          object_title: {
            string_title1: "牙科集结展",
            string_title2: "Europeka dental assembly",
          },
          height: 721,
        },
        {
          component: "McCard71",
          name: "卡片71",
          icon: "icon-zujian",
          object: {
            title1: "和我们一起，构建实时互动的世界",
            title2: "超4000家企业用户，百余种场景解决方案",
            button: "加入我们",
            jump: {},
            array: [
              {
                id: 1,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673580924466ce.png",
              },
              {
                id: 2,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673580937438ce.png",
              },
              {
                id: 3,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673580943567ce.png",
              },
              {
                id: 4,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673580950767ce.png",
              },
            ],
          },
          id: "1677330064kVXjeZ",
          height: 540,
        },
        {
          component: "McCard83",
          name: "卡片83",
          icon: "icon-zujian",
          object: {
            title: "产品功能",
            bg: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590803688ce.png",
            img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590827577ce.png",
            array: [
              {
                id: 1,
                title: "多平台支持直播",
                text: "PC桌面推流助手 | APP | 小程序 | H5 | 网页",
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590893076ce.png",
              },
              {
                id: 2,
                title: "个性化打造品牌专属直播间",
                text: "自定义皮肤 | 直播图文简介 | 直播间自定义横幅 | 海报分享 | 微信分享",
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590931592ce.png",
              },
              {
                id: 3,
                title: "多直播形式，满足各种场景",
                text: "视频直播 | 音乐直播 | 文档直播 | 屏幕共享 | 互动白板 | 伪直播",
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590910674ce.png",
              },
              {
                id: 4,
                title: "自动监播，保障直播安全",
                text: "聊天审核 | 智能鉴黄 | 直播防录屏 | 内容加密 | 人工禁播",
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590925885ce.png",
              },
              {
                id: 5,
                title: "权限观看，限定直播观看人群",
                text: "试播 | 指定用户观看 | 密码观看 | 私密直播 | 公开直播",
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590897214ce.png",
              },
              {
                id: 6,
                title: "直播分级管理，更有条不紊",
                text: "直播管理 | 商品管理 | 观众管理 | 回放管理 | 组织管理 | 权限管理",
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590915617ce.png",
              },
              {
                id: 7,
                title: "直播间互动工具，氛围拉满",
                text: "签到 | 点赞 | 打赏 | 抽奖 | 图片推送 | 聊天 | 二维码关注 | 音视频连麦",
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590901691ce.png",
              },
              {
                id: 8,
                title: "多维度直播统计数据分析，更科学",
                text: "直播间分析 | 商品统计分析 | 观众画像分析 | 主播统计分析",
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590920625ce.png",
              },
            ],
          },
          id: "1677330071JS8WV8",
          height: 1199,
        },
        {
          component: "McCard78",
          name: "卡片78",
          icon: "icon-zujian",
          object: {
            title: "产品功能",
            array: [
              {
                id: 1,
                title: "全渠道覆盖，客户无限触达",
                text: "网页、QQ、微信公众号、小程序、APP、H5",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589718439ce.png",
              },
              {
                id: 2,
                title: "接客户喜好接入，按业务需求沟通",
                text: "视频接入、语音接入、文字接入、电话接入、智能AI机器人应答",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589706380ce.png",
              },
              {
                id: 3,
                title: "智能IVR，方便远程协助，提升服务质量",
                text: "音频通话、视频通话、多方视频通话、面签面审、共享屏幕、共享文档、互动白板、电子签名、视频中推送",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589723515ce.png",
              },
              {
                id: 4,
                title: "智能双录，永久存档",
                text: "自动录制、智能双录、多方录制、沟通存档、智能工单、视频打点、知识库管理",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589686812ce.png",
              },
              {
                id: 5,
                title: "智能质检，保障企业客服质量",
                text: "实时监控、大数据智能检查、随机抽查、多类型质检模板、录制回溯",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589697514ce.png",
              },
              {
                id: 6,
                title: "超大客服分组，自定义流程管理",
                text: "根据自身的业务需求配置不同的技能组，配置相关技能组下的客服人员，多种需求同时满足",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589712899ce.png",
              },
              {
                id: 7,
                title: "客户报表一键分析",
                text: "客户管理、客户画像、关键词分析、来源渠道、访问轨迹分析",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589692119ce.png",
              },
              {
                id: 8,
                title: "数字人",
                text: "企业形象定制，多渠道、多场景快速接入",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589734010ce.png",
              },
              {
                id: 9,
                title: "安全防护，保护系统更保护客户",
                text: "恶意防刷保护、客户隐私保密（同一客户自动分配给原客服），防信息窃取、阻攻击行为",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589728524ce.png",
              },
              {
                id: 10,
                title: "客服质量统计分析",
                text: "人工客服坐席工作量、工单量、服务质量、客户满意度",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589701730ce.png",
              },
            ],
          },
          id: "1677330068bGA3Tu",
          height: 998,
        },
        {
          component: "McCard77",
          name: "卡片77",
          icon: "icon-zujian",
          object: {
            title: "应用场景",
            array: [
              {
                id: 1,
                title: "政务场景",
                iconBlack:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673588468676ce.png",
                imgWhite:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673588444127ce.png",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673588566313ce.png",
                title1: "交通热线（122热线）",
                text1:
                  "122热线搭建视频客服系统，让群众可视频报告交通事故，并且快速自动上报位置",
                title2: "城管热线（市容报告）",
                text2:
                  "城管热线搭建视频客服系统，视频报告城市问题，帮助城管高效快速的治理城市",
                title3: "远程审批",
                text3:
                  "加快数字化建设，通过视频验证，线上签约，加快了办事效率，提升了群众幸福感",
                title4: "",
                text4: "",
                title5: "",
                text5: "",
                title6: "",
                text6: "",
              },
              {
                id: 2,
                title: "教育场景",
                iconBlack:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673588473466ce.png",
                imgWhite:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673588432674ce.png",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673588566313ce.png",
                title1: "招生咨询",
                text1:
                  "陌生人接入客服系统咨询招生信息，机构对顾客进行视频检测，预分班处理",
                title2: "远程授课",
                text2:
                  "教师与学生一对一在线答疑，辅助教学，桌面可共享，视频可回放，白板画图",
                title3: "在线辅导",
                text3:
                  "利用桌面共享、文件共享、白板互动等功能在线上对学生进行辅导、批注、修改等",
                title4: "",
                text4: "",
                title5: "",
                text5: "",
                title6: "",
                text6: "",
              },
              {
                id: 3,
                title: "金融场景",
                iconBlack:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673588459050ce.png",
                imgWhite:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673588408562ce.png",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673588566313ce.png",
                title1: "视频面签",
                text1:
                  "金融机构通过云客服的双录、AI识别、电子签的功能，完成需要当面签署的业务",
                title2: "远程信贷/信贷风控",
                text2:
                  "金融机构通过云客服的身份识别，电子签，视频沟通、双录等功能完成远程放贷",
                title3: "VIP客户服务",
                text3:
                  "针对高价值VIP客户，提供专属视频的服务通道，享受尊贵面对面服务体验",
                title4: "主动推销",
                text4:
                  "金融机构与客户“面对面”讲解，通过身份识别、电子签等快速业务转化，保证合规",
                title5: "保险定损",
                text5:
                  "勘查员实时接入云客服视频，进行汽车小额事故的定损，案件复勘",
                title6: "投保双录",
                text6:
                  "线上投保客户通过视频客服，完成身份识别，视频双录，保证合法合规",
              },
              {
                id: 4,
                title: "医疗场景",
                iconBlack:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673588494052ce.png",
                imgWhite:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673588415727ce.png",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673588566313ce.png",
                title1: "医疗救助",
                text1:
                  "医护人员通过接入医疗救助客服，快速识别和指导患者、路人对病患进行即时救助",
                title2: "医疗专家咨询",
                text2:
                  "医疗专家线上接入患者视频诊断，开展远程诊疗业务，云客服系统可满足多方会诊需求",
                title3: "术后回访（效果追踪）",
                text3:
                  "视频回访病人，通过三方视频完成专家问诊，且留存视频形成完整的教学案例",
                title4: "",
                text4: "",
                title5: "",
                text5: "",
                title6: "",
                text6: "",
              },
              {
                id: 5,
                title: "企业场景",
                iconBlack:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673588464052ce.png",
                imgWhite:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673588422788ce.png",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673588566313ce.png",
                title1: "商品导购",
                text1: "可向客户展示商品实物，追踪商品访问轨迹， 全面介绍商品",
                title2: "业务咨询",
                text2:
                  "通过屏幕共享、文档共享、三方沟通等功能向客户完成业务讲解，业务专化",
                title3: "安装指导",
                text3:
                  "通过与客户视频沟通，指导客户完成安装指导，大大提升客户服务体验度",
                title4: "远程售后指导",
                text4:
                  "客户通过视频重现问题，技术员接入定位问题，指导客户解决问题，提升客户体验",
                title5: "",
                text5: "",
                title6: "",
                text6: "",
              },
            ],
          },
          id: "1677330067IHWHwv",
          height: 840,
        },
        {
          component: "McCard72",
          name: "卡片72",
          icon: "icon-zujian",
          object: {
            title: "精选案例",
            array: [
              {
                id: 1,
                title: "社交娱乐",
                text: "智摆灵活的音视频底层架构，支持映客快速上线多人相亲交友、电台聊天室等全新模式，极大丰富了泛娱乐内容生态中的多样玩法，助力映客业务稳步升级。",
                colorBorder: "#00DDC9",
                colorTitle: "#0EA193",
                colorBg: "#EBFCF9",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673581327703ce.png",
                logo: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1672974091479ce.png",
              },
              {
                id: 2,
                title: "社交娱乐",
                text: "酷狗线上KTV对音质要求极高，智摆方案在进行语音3A前处理外，还提供混响、变声、立体声功能，让歌声丰满有层次。",
                colorBorder: "#00DDC9",
                colorTitle: "#0EA193",
                colorBg: "#EBFCF9",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673581327703ce.png",
                logo: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1672974103027ce.png",
              },
              {
                id: 3,
                title: "直播出海",
                text: "智摆音视频SDK助力Uplive实现主播同屏PK、多人连麦语聊等多种直播创新玩法，助力Uplive快速抢占印度、印尼等新兴市场。",
                colorBorder: "#00DDC9",
                colorTitle: "#0EA193",
                colorBg: "#EBFCF9",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673581327703ce.png",
                logo: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1672974116098ce.png",
              },
            ],
          },
          id: "1677330065lieXQg",
          height: 782,
        },
        {
          component: "McCard69",
          name: "卡片69",
          icon: "icon-zujian",
          object: {
            title: "福利与晋升",
            array: [
              {
                id: 1,
                title: "固定薪酬&年终奖金&股权回报",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673578564155ce.png",
              },
              {
                id: 2,
                title: "五险一金&补充商业保险",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673578559545ce.png",
              },
              {
                id: 3,
                title: "法定节假日&带薪年假",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673578569028ce.png",
              },
              {
                id: 4,
                title: "年度体检&带薪病假",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673578579352ce.png",
              },
              {
                id: 5,
                title: "年度旅游&周俱乐部活动&每日下午茶",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673578583797ce.png",
              },
              {
                id: 6,
                title: "专业大牛一对一指导提升",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673578802017ce.png",
              },
              {
                id: 7,
                title: "系统的音视频专家培养计划",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673578594594ce.png",
              },
              {
                id: 8,
                title: "广阔的发展前景",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673578599242ce.png",
              },
            ],
          },
          id: "1677330062k5sMBN",
          height: 612,
        },
        {
          component: "McCard96",
          name: "卡片96",
          icon: "icon-zujian",
          array_list: [
            {
              id: 0,
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676603202046ce.png",
              switch: false,
              jump: "",
            },
            {
              id: 1,
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676603209150ce.png",
              switch: false,
              jump: "",
            },
          ],
          id: "1677330127FQkRlS",
          height: 500,
        },
        {
          component: "McCard76",
          name: "卡片76",
          icon: "icon-zujian",
          object: {
            title1: "视频云客服",
            title2:
              "全渠道接入智能客服，数字人机协同服务、实时视频通话、身份识别、远程协助、线上签名等协同组合能力，帮助企业快速构建非接触式服务和远程服务",
            button: "申请试用",
            jump: {},
            img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673588286283ce.png",
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673588331416ce.png",
          },
          id: "167733014754F2IE",
          height: 645.75,
        },
        {
          component: "McCard78",
          name: "卡片78",
          icon: "icon-zujian",
          object: {
            title: "产品功能",
            array: [
              {
                id: 1,
                title: "全渠道覆盖，客户无限触达",
                text: "网页、QQ、微信公众号、小程序、APP、H5",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589718439ce.png",
              },
              {
                id: 2,
                title: "接客户喜好接入，按业务需求沟通",
                text: "视频接入、语音接入、文字接入、电话接入、智能AI机器人应答",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589706380ce.png",
              },
              {
                id: 3,
                title: "智能IVR，方便远程协助，提升服务质量",
                text: "音频通话、视频通话、多方视频通话、面签面审、共享屏幕、共享文档、互动白板、电子签名、视频中推送",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589723515ce.png",
              },
              {
                id: 4,
                title: "智能双录，永久存档",
                text: "自动录制、智能双录、多方录制、沟通存档、智能工单、视频打点、知识库管理",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589686812ce.png",
              },
              {
                id: 5,
                title: "智能质检，保障企业客服质量",
                text: "实时监控、大数据智能检查、随机抽查、多类型质检模板、录制回溯",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589697514ce.png",
              },
              {
                id: 6,
                title: "超大客服分组，自定义流程管理",
                text: "根据自身的业务需求配置不同的技能组，配置相关技能组下的客服人员，多种需求同时满足",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589712899ce.png",
              },
              {
                id: 7,
                title: "客户报表一键分析",
                text: "客户管理、客户画像、关键词分析、来源渠道、访问轨迹分析",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589692119ce.png",
              },
              {
                id: 8,
                title: "数字人",
                text: "企业形象定制，多渠道、多场景快速接入",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589734010ce.png",
              },
              {
                id: 9,
                title: "安全防护，保护系统更保护客户",
                text: "恶意防刷保护、客户隐私保密（同一客户自动分配给原客服），防信息窃取、阻攻击行为",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589728524ce.png",
              },
              {
                id: 10,
                title: "客服质量统计分析",
                text: "人工客服坐席工作量、工单量、服务质量、客户满意度",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589701730ce.png",
              },
            ],
          },
          id: "1677330144Di6Kay",
          height: 998,
        },
        {
          component: "McCard8",
          name: "卡片8",
          icon: "icon-zujian",
          object_title: {
            title1: "值得信赖的标题",
            title2: "Mapping Your Business",
          },
          array: [
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              name: "标题文本1",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              name: "标题文本2",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              name: "标题文本3",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              name: "标题文本4",
            },
          ],
          array_list: [
            {
              name: "标题1",
              icon1:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_point_grey.png",
              icon2:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_point_white.png",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              name: "标题2",
              icon1:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_line_grey.png",
              icon2:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_line_white.png",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              name: "标题3",
              icon1:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_surface_grey.png",
              icon2:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_surface_white1.png",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              name: "标题4",
              icon1:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_3D_grey.png",
              icon2:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_3D_white.png",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              name: "标题5",
              icon1:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_heat_grey.png",
              icon2:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_heat_white.png",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
          ],
          id: "1677330143UPV7W5",
          height: 777,
        },
        {
          component: "McCard81",
          name: "卡片81",
          icon: "icon-zujian",
          object: {
            title: "智摆优势",
            array: [
              {
                id: 1,
                title: "毫秒级音画延迟",
                text: "覆盖 200+ 国家/地区低延迟直播场景",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590229403ce.png",
              },
              {
                id: 2,
                title: "超高并发极低卡顿",
                text: "支持千万级并发拉流，抗丢包，抗弱网，流畅稳定",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590233823ce.png",
              },
              {
                id: 3,
                title: "弱网高可用",
                text: "自研 QoS 策略，在70%丢包下，仍能稳定流畅播放",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590238798ce.png",
              },
              {
                id: 4,
                title: "自研音视频技术",
                text: "自研底层音视频引擎，具备数十项技术专利",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590307322ce.png",
              },
              {
                id: 5,
                title: "支持私有化定制",
                text: "支持多租户SAAS服务，同时支持私有化混合云部署",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590224281ce.png",
              },
            ],
          },
          id: "1677330141w6vpkq",
          height: 426,
        },
        {
          component: "McCard85",
          name: "卡片85",
          icon: "icon-zujian",
          object: {
            title1: "智能云SAAS响应式",
            title2: "建站系统",
            text: "不懂设计、不懂编程，在线制作响应式网站。动动鼠标、想改就改、自由添加、支持深度个性化定制，免费建站。",
            button: "免费建站",
            jump: {},
            video:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673512757294ce.png",
          },
          id: "1677330139YGLson",
          height: 1150,
        },
        {
          component: "McCard92",
          name: "卡片92",
          icon: "icon-zujian",
          object: {
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676602997799ce.png",
            title: "马上制作—微信H5店铺",
            button: {
              text: "免费拥有",
              jump: {},
            },
          },
          id: "1677330129tvMKI6",
          height: 284,
        },
        {
          component: "McCard91",
          name: "卡片91",
          icon: "icon-zujian",
          title: {
            title: "他们都在用凡科教育",
          },
          array_list: [
            {
              id: 0,
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676602579507ce.png",
              code: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676602492369ce.png",
              text: "PlayABC",
            },
            {
              id: 1,
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676602586256ce.png",
              code: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676602492369ce.png",
              text: "MEGASOUL线上教学",
            },
            {
              id: 2,
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676602595983ce.png",
              code: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676602492369ce.png",
              text: "长安强脑教育",
            },
            {
              id: 3,
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676602605396ce.png",
              code: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676602492369ce.png",
              text: "伦敦Q学堂",
            },
          ],
          id: "1677330137cn6i5j",
          height: 742,
        },
        {
          component: "McCard95",
          name: "卡片95",
          icon: "icon-zujian",
          title: {
            title: "100+ 教育运营工具",
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676616708860ce.png",
          },
          array_list: [
            {
              id: 0,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "店铺设计",
              switch: false,
            },
            {
              id: 1,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "海量模板",
              switch: false,
            },
            {
              id: 2,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "拖拽组件",
              switch: false,
            },
            {
              id: 3,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "多端发布",
              switch: false,
            },
            {
              id: 4,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "信息展示",
              switch: false,
            },
            {
              id: 5,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "报名表单",
              switch: false,
            },
            {
              id: 6,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "在线咨询",
              switch: false,
            },
            {
              id: 7,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "付费会员",
              switch: false,
            },
            {
              id: 8,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "等级会员",
              switch: false,
            },
            {
              id: 9,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "会员权益",
              switch: false,
            },
            {
              id: 10,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "客户管理",
              switch: false,
            },
            {
              id: 11,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "智能名片",
              switch: false,
            },
            {
              id: 12,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "访客列表",
              switch: false,
            },
            {
              id: 13,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "商机雷达",
              switch: false,
            },
            {
              id: 14,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "推广员",
              switch: false,
            },
            {
              id: 15,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "转介绍",
              switch: false,
            },
            {
              id: 16,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "兑换码",
              switch: false,
            },
            {
              id: 17,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "优惠券",
              switch: false,
            },
            {
              id: 18,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "流量主",
              switch: false,
            },
            {
              id: 19,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "400电话",
              switch: false,
            },
            {
              id: 20,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "模板消息",
              switch: false,
            },
          ],
          id: "16773301312fsYIO",
          height: 506,
        },
        {
          component: "McCard97",
          name: "卡片97",
          icon: "icon-zujian",
          title: {
            title1: "紧抓微信60+个流量窗口",
            title2: "7亿+用户选择小程序",
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676617901470ce.png",
          },
          array_list: [
            {
              id: 1,
              title: "一扫直达页面",
              text: "轻松获取页面路径链接及二维码",
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676340245756ce.png",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
            },
            {
              id: 2,
              title: "搜索小程序",
              text: "设置页面关键词，占位微信搜索",
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676340245756ce.png",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
            },
            {
              id: 3,
              title: "附近的小程序",
              text: "覆盖5公里内，为线下门店引流",
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676340245756ce.png",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
            },
            {
              id: 4,
              title: "公众号跳转小程序",
              text: "小程序关联公众号，促导流转化",
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676340245756ce.png",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
            },
            {
              id: 5,
              title: "分享小程序",
              text: "自定义分享样式，实现裂变分享",
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676340245756ce.png",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
            },
            {
              id: 6,
              title: "小程序互相跳转",
              text: "实现场景互补、服务延伸",
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676340245756ce.png",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
            },
            {
              id: 7,
              title: "朋友圈广告",
              text: "利用优惠活动高效促进到店消费",
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676340245756ce.png",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
            },
            {
              id: 8,
              title: "最近使用列表",
              text: "提高小程序露出率及用户粘性",
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676340245756ce.png",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
            },
          ],
          id: "1677330135sseUoP",
          height: 720,
        },
        {
          component: "McCooperation",
          icon: "el-icon-menu",
          name: "合作伙伴",
          id: "1650970889WfTFo5",
          height: 303,
        },
        {
          component: "McCard99",
          name: "卡片99",
          icon: "icon-zujian",
          title: {
            title1: "各行业精选案例",
            title2: "* 案例仅供参考",
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676623783408ce.png",
            button: {
              text: "查看更多",
              jump: {},
            },
          },
          array_list: [
            {
              id: 1,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676359487549ce.png",
              image:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676621032650ce.png",
              text: "广州梦初婚礼",
            },
            {
              id: 2,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676359487549ce.png",
              image:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676621032650ce.png",
              text: "江西研途考研",
            },
            {
              id: 3,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676359487549ce.png",
              image:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676621032650ce.png",
              text: "聚享湘阴",
            },
            {
              id: 4,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676359487549ce.png",
              image:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676621032650ce.png",
              text: "E美秀视觉",
            },
            {
              id: 5,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676359487549ce.png",
              image:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676621032650ce.png",
              text: "润芳可会员",
            },
          ],
          id: "16773301330W5dXI",
          height: 749,
        },
        {
          component: "McFooter",
          images: [
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_13.png",
              url: "/pages/index/custom/custom?pageId=1649505050MDQn",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_14.png",
              url: "/pages/index/custom/custom?pageId=1649505050MDQn",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_16.png",
              url: "/pages/index/custom/custom?pageId=1649505050MDQn",
            },
          ],
          bottom: {
            images: [
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_05.png",
                url: "/pages/index/custom/custom?pageId=1649505050MDQn",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_07.png",
                url: "/pages/index/custom/custom?pageId=1649505050MDQn",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_09.png",
                url: "/pages/index/custom/custom?pageId=1649505050MDQn",
              },
            ],
            address: "山东省济宁市北湖新区新城发展大厦B座15楼",
            text1: "欧罗德卡 版权所有",
            text2: "鲁ICP备15123347号",
            icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_15.png",
            logo: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/logo.png",
            time: "2021-2022",
          },
          icon: "el-icon-menu",
          name: "底部版权",
          id: "1652323539XfC7Ug",
          list: {
            list1: {
              title: "产品",
              content: [
                {
                  text: "机用根管锉",
                  url: "/pages/index/custom/custom?pageId=1649499891FFNR",
                },
                {
                  text: "马达一体机",
                  url: "/pages/index/custom/custom?pageId=1649499891FFNR",
                },
                {
                  text: "无线根管预备机",
                  url: "/pages/index/custom/custom?pageId=1649499891FFNR",
                },
                {
                  text: "手用锉",
                  url: "/pages/index/custom/custom?pageId=1649499891FFNR",
                },
                {
                  text: "Iteeth 3D打印仿真牙",
                  url: "/pages/index/custom/custom?pageId=1649499891FFNR",
                },
                {
                  text: "根管锉消毒盒",
                  url: "/pages/index/custom/custom?pageId=1649499891FFNR",
                },
                {
                  text: "吸潮纸尖",
                  url: "/pages/index/custom/custom?pageId=1649499891FFNR",
                },
                {
                  text: "牙胶尖修整器",
                  url: "/pages/index/custom/custom?pageId=1649499891FFNR",
                },
              ],
            },
            list5: {
              title: "公司",
              content: [
                {
                  text: "关于我们",
                  url: "/pages/index/custom/custom?pageId=1649505050MDQn",
                },
                {
                  text: "市场活动",
                  url: "/pages/index/custom/custom?pageId=1649505041gZ4r",
                },
                {
                  text: "公告资讯",
                  url: "/pages/index/custom/custom?pageId=1649505041gZ4r",
                },
                {
                  text: "加盟代理",
                  url: "/pages/index/custom/custom?pageId=1649558335H3vj",
                },
                {
                  text: "联系我们",
                  url: "/pages/index/custom/custom?pageId=1649505050MDQn",
                },
              ],
            },
            list4: {
              title: "服务中心",
              content: [
                {
                  text: "售前培训",
                  url: "/pages/index/custom/custom?pageId=1649576074v3XE",
                },
                {
                  text: "帮助文档",
                  url: "/pages/index/custom/custom?pageId=1649576074v3XE",
                },
                {
                  text: "售后反馈",
                  url: "/pages/index/custom/custom?pageId=16495584549CtV",
                },
              ],
            },
            list3: {
              title: "生产中心",
              content: [
                {
                  text: "山东济宁兖州研发中心",
                  url: "/pages/index/custom/custom?pageId=1649505050MDQn",
                },
                {
                  text: "济宁高新区研发中心",
                  url: "/pages/index/custom/custom?pageId=1649505050MDQn",
                },
              ],
            },
            list2: {
              title: "解决方案",
              content: [
                {
                  text: "售前教程",
                  url: "/pages/index/custom/custom?pageId=1649576074v3XE",
                },
                {
                  text: "培训资料",
                  url: "/pages/index/custom/custom?pageId=165018847192gN",
                },
                {
                  text: "售后反馈",
                  url: "/pages/index/custom/custom?pageId=16495584549CtV",
                },
              ],
            },
          },
          height: 416.5,
          object: {
            img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/bottomBg.png",
            btns: [
              {
                name: "立即加盟",
                url: "/pages/index/custom/custom?pageId=1649558335H3vj",
              },
              {
                name: "售前售后",
                url: "/pages/index/custom/custom?pageId=1649576074v3XE",
              },
            ],
            text: "感谢您对我司品牌的关心与厚爱，全国招募经销商，可咨询 0537-2522017",
            list: [
              {
                text1: "400+",
                text2: "全国销售商",
              },
              {
                text1: "2.0",
                text2: "多种复刻产品",
              },
              {
                text1: "进口",
                text2: "德国原进口品质",
              },
              {
                text1: "100+",
                text2: "售前售后解决方案",
              },
            ],
          },
        },
      ],
      id: "000000",
      home: true,
    },
    {
      name: "产品中心",
      componentList: [
        {
          component: "McHeader",
          name: "顶部导航",
          icon: "icon-zujian",
          object: {
            object_bg: {
              color_bg: "#333333",
            },
            object_text: {
              color_text: "#ffffff",
            },
            object_right: {
              string_title: "English",
              jump_right: {},
              color_text: "#664498",
              switch_show: true,
            },
          },
          id: "1672551130JdvtID",
          height: 68,
        },
        {
          component: "McImg",
          icon: "icon-image",
          name: "图片",
          id: "1650961861gQLhwG",
          imageStyle: {
            cmpUpperPadding: 0,
            cmpLowerPadding: 0,
            pagePadding: 0,
            cmpRadius: 0,
          },
          height: 400.5,
          imageValue: {
            jumpPath: [],
            imagePath:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1655117216019ce.png",
          },
        },
        {
          component: "McCard19",
          name: "卡片19",
          icon: "icon-zujian",
          title: {
            title1: "全网营销服务",
            title2:
              "简约网络提供设计制作、功能定制开发、网站优化推广，一站式互联网服务",
          },
          object_list: {
            service1: {
              image:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673244459511ce.png",
              title: "视觉营销服务",
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673244890213ce.png",
              switch: true,
              list: [
                {
                  id: 1,
                  text1: "网站广告图",
                  text2: "提供宣传广告图专业设计",
                },
                {
                  id: 2,
                  text1: "LOGO设计",
                  text2: "为企业定制专属标志形象",
                },
                {
                  id: 3,
                  text1: "企业宣传册",
                  text2: "手册、三折页、统一风格设计",
                },
                {
                  id: 4,
                  text1: "商品设计",
                  text2: "产品抠图、商品详情页介绍",
                },
              ],
              more: {
                text: "了解更多",
                jump: {},
                switch: true,
              },
            },
            service2: {
              image:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673244486562ce.png",
              title: "HTTPS安全服务",
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673244890213ce.png",
              switch: true,
              list: [
                {
                  id: 1,
                  text1: "SSL证书加密",
                  text2: "SSL证书加密",
                },
                {
                  id: 2,
                  text1: "绿色挂锁表示",
                  text2: "网站域名显示绿色挂锁",
                },
                {
                  id: 3,
                  text1: "安全访问",
                  text2: "网站信息数据深度加密",
                },
                {
                  id: 4,
                  text1: "账户安全",
                  text2: "账户不怕盗、防木马中毒",
                },
              ],
              more: {
                text: "了解更多",
                jump: {},
                switch: true,
              },
            },
            service3: {
              image:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673244496922ce.png",
              title: "定制开发服务",
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673244890213ce.png",
              switch: true,
              list: [
                {
                  id: 1,
                  text1: "网站二次开发",
                  text2: "定制企业 / 个人专属官网",
                },
                {
                  id: 2,
                  text1: "商城定制开发",
                  text2: "打造微商城、公众号等服务",
                },
                {
                  id: 3,
                  text1: "APP开发/定制",
                  text2: "打造属于自己的APP商城应用",
                },
                {
                  id: 4,
                  text1: "小程序开发 / 定制",
                  text2: "即用即走的小程序、抢占先机",
                },
              ],
              more: {
                text: "了解更多",
                jump: {},
                switch: true,
              },
            },
            service4: {
              image:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673244506422ce.png",
              title: "营销推广服务",
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673244890213ce.png",
              switch: true,
              list: [
                {
                  id: 1,
                  text1: "SEO优化",
                  text2: "优化名词、提升搜索率",
                },
                {
                  id: 2,
                  text1: "SEM推广",
                  text2: "百度、360等开户、代推广",
                },
                {
                  id: 3,
                  text1: "软文推广",
                  text2: "通过自媒体建立企业口碑",
                },
                {
                  id: 4,
                  text1: "短信营销",
                  text2: "稳定安全、成功计费、99%到达率",
                },
              ],
              more: {
                text: "了解更多",
                jump: {},
                switch: true,
              },
            },
          },
          id: "1677331890MAS9yf",
          height: 752,
        },
        {
          component: "McCard50",
          name: "卡片50",
          icon: "icon-zujian",
          object: {
            title1: "购买授权",
            title2: "可根据公司实际业务需求，灵活选择授权模式",
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673316012252ce.png",
            empower1: {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673322830237ce.png",
              title: "企业版授权",
              text: "根据公司授权，源码全无加密，不限制域名授权次数，多对一技术支持、尊享售后服务",
              jump: {},
            },
            empower2: {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673316064318ce.png",
              title: "商业版授权",
              text: "域名授权模式，满足中小型企业应用需求。代码全开源支持二次开发，享受终身免费升级、官方会员服务支持",
              jump: {},
            },
          },
          id: "1677331919jaUGUL",
          height: 515,
        },
        {
          component: "McCard54",
          name: "卡片54",
          icon: "icon-zujian",
          object: {
            title: "智摆优势",
            array: [
              {
                id: 1,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673572693511ce.png",
                title: "自研音视频",
                text1: "自研音视频引擎，数十项专利",
                text2: "自研QoS策略，70%丢包下仍能保证稳定的视频体验",
                text3: "全球212国家/地区低延迟视频",
              },
              {
                id: 2,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673572718157ce.png",
                title: "软、硬件终端完美融合",
                text1: "支持全向麦克风，8米拾音及360度定位",
                text2:
                  "兼容市面硬件厂商95%产品，更好的兼容适配老旧硬件最大作用发挥",
                text3: "支持480P-4K高清画质，近原声质",
              },
              {
                id: 3,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673572709310ce.png",
                title: "安全合规",
                text1: "信创产品，完全符合相关政策要求",
                text2: "公安部等级保护测评三级认证",
                text3: "可信云企业级SaaS认证",
              },
              {
                id: 4,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673572701442ce.png",
                title: "灵活部署",
                text1: "支持公有云、私有化、混合云多模式",
                text2: "支持电脑、手机、平板、网页、小程序等全场景会议终端接入",
                text3: "提供SDK/API，支持定制化开发",
              },
            ],
          },
          id: "1677331921uGBuAU",
        },
        {
          component: "McCard4",
          name: "卡片4",
          icon: "icon-zujian",
          object_list: {
            array: [
              {
                title: "标题1",
                data: "1100",
                switch: true,
              },
              {
                title: "标题2",
                data: "240",
                switch: true,
              },
              {
                title: "标题3",
                data: "8000",
                switch: true,
              },
              {
                title: "标题4",
                data: "900",
                switch: true,
              },
              {
                title: "标题5",
                data: "1100",
                switch: true,
              },
              {
                title: "标题6",
                data: "160",
                switch: true,
              },
            ],
            video:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/shipin1.mp4",
            text1: "用真实数据展示实力",
            text2: "描述文字1",
            text3: "描述文字2",
            text4: "描述文字3",
            text5: "描述文字4",
          },
          id: "1677331917WH11h3",
          height: 956.5,
        },
        {
          component: "McCard42",
          name: "卡片42",
          icon: "icon-zujian",
          title: {
            title1: "构建新零售社交电商全场景解决方案",
            title2:
              "追踪新零售模式新趋势，打造集直播、分销、拼团、砍价、抽奖等热门社交电商玩法；打通多端用户数据，构建全场景新零售社交电商解决方案",
          },
          object_list: {
            img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673255483000ce.png",
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673255472999ce.png",
            list1: {
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673311809334ce.png",
              title: "多终端覆盖",
              array: [
                {
                  id: 1,
                  text: "PC端",
                },
                {
                  id: 2,
                  text: "H5",
                },
                {
                  id: 3,
                  text: "小程序",
                },
                {
                  id: 4,
                  text: "PC端管理后台",
                },
                {
                  id: 5,
                  text: "APP",
                },
                {
                  id: 6,
                  text: "移动端订单处理",
                },
              ],
            },
            list2: {
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673311839838ce.png",
              title: "店铺管理",
              array: [
                {
                  id: 1,
                  text: "店铺diy装修",
                },
                {
                  id: 2,
                  text: "数据统计",
                },
                {
                  id: 3,
                  text: "线下多点核销",
                },
                {
                  id: 4,
                  text: "物流查询",
                },
                {
                  id: 5,
                  text: "会员系统",
                },
                {
                  id: 6,
                  text: "发票管理",
                },
                {
                  id: 7,
                  text: "客服系统",
                },
                {
                  id: 8,
                  text: "电子面单",
                },
              ],
            },
            list3: {
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673311830014ce.png",
              title: "营销拓客",
              array: [
                {
                  id: 1,
                  text: "直播带货",
                },
                {
                  id: 2,
                  text: "限时秒杀",
                },
                {
                  id: 3,
                  text: "多人拼团",
                },
                {
                  id: 4,
                  text: "优惠券",
                },
                {
                  id: 5,
                  text: "砍价助力",
                },
                {
                  id: 6,
                  text: "满额包邮",
                },
              ],
            },
            list4: {
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673311820193ce.png",
              title: "会员/分销",
              array: [
                {
                  id: 1,
                  text: "付费会员",
                },
                {
                  id: 2,
                  text: "事业部代理分销",
                },
                {
                  id: 3,
                  text: "等级会员",
                },
                {
                  id: 4,
                  text: "人人分销",
                },
                {
                  id: 5,
                  text: "会员折扣",
                },
                {
                  id: 6,
                  text: "指定分销",
                },
                {
                  id: 7,
                  text: "会员积分",
                },
                {
                  id: 8,
                  text: "满额分销",
                },
              ],
            },
          },
          id: "1677331915uunZtS",
          height: 858,
        },
        {
          component: "McCard34",
          name: "卡片34",
          icon: "icon-zujian",
          object: {
            title1: "短信营销",
            title2:
              "为企业提供三网合一106短信平台、群发短信、短信验证码、短信营销,节约大量的人力和物力投入.",
          },
          array: [
            {
              id: 1,
              title: "营销类",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673252865734ce.png",
              imgHover:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673252872707ce.png",
              switch: true,
              title1: "短信营销",
              text1: "优质106短信群发通道,到达率高达99%,更迅速、更稳定！",
              jump1: "",
              switch1: true,
              title2: "彩信营销",
              text2:
                "创意的营销形式,更配有丰富的图片、声音、动画等多媒体内容选择!",
              jump2: "",
              switch2: true,
            },
            {
              id: 2,
              title: "短信验证",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673252849099ce.png",
              imgHover:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673252855599ce.png",
              switch: true,
              title1: "短信验证",
              text1: "专线机房专业运维保障,到达率高,降低拦截率,杜绝刷单！",
              jump1: "",
              switch1: true,
              title2: "语音验证",
              text2: "短信群发平台专用通道,分布式集群架构,服务器毫秒级响应.",
              jump2: "",
              switch2: true,
            },
            {
              id: 3,
              title: "通知类",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673252835837ce.png",
              imgHover:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673252841660ce.png",
              switch: true,
              title1: "短信通知",
              text1: "优质资源通道、三网合一、全国覆盖、支持失败返还！",
              jump1: "",
              switch1: true,
              title2: "语音通知",
              text2: "多通道智能切换,海量并发零堵塞,确保呼叫不被屏蔽!",
              jump2: "",
              switch2: true,
            },
            {
              id: 4,
              title: "增值类",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673252820310ce.png",
              imgHover:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673252826093ce.png",
              switch: true,
              title1: "国际短信",
              text1: "短信群发支持发送多国语言,满足企业海外市场拓展需求.",
              jump1: "",
              switch1: true,
              title2: "视频短信",
              text2: "可容纳3M媒体信息内容,给予企业充分的短信营销展示空间.",
              jump2: "",
              switch2: true,
            },
          ],
          id: "1677331911bjoQBn",
          height: 621,
        },
        {
          component: "McCard4",
          name: "卡片4",
          icon: "icon-zujian",
          object_list: {
            array: [
              {
                title: "标题1",
                data: "1100",
                switch: true,
              },
              {
                title: "标题2",
                data: "240",
                switch: true,
              },
              {
                title: "标题3",
                data: "8000",
                switch: true,
              },
              {
                title: "标题4",
                data: "900",
                switch: true,
              },
              {
                title: "标题5",
                data: "1100",
                switch: true,
              },
              {
                title: "标题6",
                data: "160",
                switch: true,
              },
            ],
            video:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/shipin1.mp4",
            text1: "用真实数据展示实力",
            text2: "描述文字1",
            text3: "描述文字2",
            text4: "描述文字3",
            text5: "描述文字4",
          },
          id: "1677331914fqF6t5",
          height: 956.5,
        },
        {
          component: "McCard3",
          name: "卡片3",
          icon: "icon-zujian",
          object_title: {
            string_title1: "这是个标题",
            string_title2: "Mapping Your Business",
            array_list: [
              {
                string_list: "介绍内容描述1",
              },
              {
                string_list: "介绍内容描述2",
              },
              {
                string_list: "介绍内容描述3",
              },
              {
                string_list: "介绍内容描述4",
              },
            ],
            upload_image1:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei2.png",
            upload_image2:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei2.png",
            url: "查看详情",
          },
          id: "1677331908I129ZU",
          height: 731,
        },
        {
          component: "McCard24",
          name: "卡片24",
          icon: "icon-zujian",
          title: {
            title1: "简约网络商城核心功能",
            title2:
              "致力于帮助更多的中小企业开展线上线下O2O、B2C、新零售业务，实现互联网转型",
          },
          array: [
            {
              id: 1,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673245987299ce.png",
              title: "分销拓客",
              text: "通过微信社交好友分销推广发展商城忠实粉丝",
              switch: true,
            },
            {
              id: 2,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246002422ce.png",
              title: "拼团",
              text: "邀请好友拼团，商城订单成倍提升，增加收益",
              switch: true,
            },
            {
              id: 3,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246009992ce.png",
              title: "砍价",
              text: "邀请好友砍价，增加商城曝光，获取意向客户",
              switch: true,
            },
            {
              id: 4,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246020266ce.png",
              title: "秒杀",
              text: "推出爆款产品，吸引用户下单，获取种子用户",
              switch: true,
            },
            {
              id: 5,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246029711ce.png",
              title: "优惠劵",
              text: "激活沉睡用户，有效刺激消费",
              switch: true,
            },
            {
              id: 6,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246138721ce.png",
              title: "产品属性",
              text: "自定义产品颜色，规格，价格等不同属性，操作便捷",
              switch: true,
            },
            {
              id: 7,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246145611ce.png",
              title: "在线支付",
              text: "邀请好友拼团，商城订单成倍提升，增加收益",
              switch: true,
            },
            {
              id: 8,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246163658ce.png",
              title: "积分系统",
              text: "根据积分划分会员等级，积分可付款抵扣",
              switch: true,
            },
            {
              id: 9,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246172044ce.png",
              title: "在线客服",
              text: "推出爆款产品，吸引用户下单，获取种子用户",
              switch: true,
            },
            {
              id: 10,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246154522ce.png",
              title: "订单提醒",
              text: "订单状态及时提醒，通知到用户及客服人员",
              switch: true,
            },
            {
              id: 11,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246273174ce.png",
              title: "虚拟销量",
              text: "自定义商城产品虚拟销量摆脱前期推广零销量尴尬",
              switch: true,
            },
            {
              id: 12,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246266218ce.png",
              title: "用户画像",
              text: "用户消费行为，消费偏好，消费能力归类定义",
              switch: true,
            },
            {
              id: 13,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246257865ce.png",
              title: "精准营销",
              text: "进行大数据分析，对目标客户精准营销，有的放矢",
              switch: true,
            },
            {
              id: 14,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246287263ce.png",
              title: "反馈评价",
              text: "用户对产品服务进行评价，增加商城信认度",
              switch: true,
            },
            {
              id: 15,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673246280582ce.png",
              title: "物流追踪",
              text: "发货商品物流跟踪，用户可实时查询订单物流状态",
              switch: true,
            },
          ],
          id: "16773319024qbGIC",
          height: 842.78125,
        },
        {
          component: "McCard20",
          name: "卡片20",
          icon: "icon-zujian",
          title: {
            title1: "性能强大、安全、稳定的 SaaS 产品",
            title2: "系统稳定高于一切，让商家经营有保障，消费者购物更放心",
            imageBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673245545167ce.png",
            jump: {
              text: "了解详情",
              url: {},
              switch: true,
            },
          },
          array_list: [
            {
              id: 1,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673245445592ce.png",
              title1: "护航店铺稳定",
              title2: "核心服务中断将获补偿",
              switch: true,
            },
            {
              id: 2,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673245457366ce.png",
              title1: "护航数据安全",
              title2: "加密、异地备份",
              switch: true,
            },
            {
              id: 3,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673245465790ce.png",
              title1: "护航消费者体验",
              title2: "防止流氓广告乱入",
              switch: true,
            },
            {
              id: 4,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673245473854ce.png",
              title1: "护航页面打开速度",
              title2: "页面打开速度仅需1秒",
              switch: true,
            },
          ],
          id: "1677331893Ex2K1Q",
          height: 413,
        },
        {
          component: "McCard26",
          name: "卡片26",
          icon: "icon-zujian",
          object: {
            title: "简约网络产品代理商全国火热招募",
            text: "代理简约网络，轻松掘金内容付费及小程序",
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673251342518ce.png",
            button1: {
              text: "代理商登录",
              jump: {},
              switch: true,
            },
            button2: {
              text: "申请代理商",
              jump: {},
              switch: true,
            },
          },
          id: "1677331901ioOO6v",
          height: 274,
        },
        {
          component: "McCard6",
          array_list: [
            {
              string_text: "介绍描述文本处介绍描述文本处",
              upload_img:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              string_title3: "标题文本1",
            },
            {
              string_text: "介绍描述文本处介绍描述文本处",
              upload_img:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              string_title3: "标题文本2",
            },
            {
              string_text: "介绍描述文本处介绍描述文本处",
              upload_img:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              string_title3: "标题文本3",
            },
            {
              string_text: "介绍描述文本处介绍描述文本处",
              upload_img:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              string_title3: "标题文本4",
            },
          ],
          name: "卡片6",
          icon: "icon-zujian",
          object_title: {
            title1: "值得信赖的标题",
            title2: "Mapping Your Business",
          },
          id: "1657939541Wgw4Zq",
          height: 622,
        },
        {
          object_image: {
            array: [
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
              {
                upload_image:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              },
            ],
          },
          component: "McCard5",
          name: "卡片5",
          icon: "icon-zujian",
          object_title: {
            string_title1: "值得信赖的标题",
            string_title2: "Mapping Your Business",
          },
          id: "16579395363mmKhT",
          height: 721,
        },
        {
          component: "McProductList",
          icon: "el-icon-menu",
          name: "产品列表",
          id: "1652332205mtaGyP",
          height: 233,
        },
        {
          component: "McFooter",
          images: [
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_13.png",
              url: "https://www.baidu.com",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_14.png",
              url: "https://www.baidu.com",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_16.png",
              url: "https://www.baidu.com",
            },
          ],
          bottom: {
            images: [
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_05.png",
                url: "https://www.baidu.com",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_07.png",
                url: "https://www.baidu.com",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_09.png",
                url: "https://www.baidu.com",
              },
            ],
            address: "山东省济宁市北湖新区新城发展大厦B座15楼",
            text1: "欧罗德卡 版权所有",
            text2: "鲁ICP备15123347号",
            icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_15.png",
            logo: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/logo.png",
            time: "2021-2022",
          },
          icon: "el-icon-menu",
          name: "底部版权",
          id: "16523322158FfXLU",
          list: {
            list1: {
              title: "产品",
              content: [
                {
                  text: "机用根管锉",
                  url: "https://www.baidu.com",
                },
                {
                  text: "马达一体机",
                  url: "https://www.baidu.com",
                },
                {
                  text: "无线根管预备机",
                  url: "https://www.baidu.com",
                },
                {
                  text: "手用锉",
                  url: "https://www.baidu.com",
                },
                {
                  text: "Iteeth 3D打印仿真牙",
                  url: "https://www.baidu.com",
                },
                {
                  text: "根管锉消毒盒",
                  url: "https://www.baidu.com",
                },
                {
                  text: "吸潮纸尖",
                  url: "https://www.baidu.com",
                },
                {
                  text: "牙胶尖修整器",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list5: {
              title: "公司",
              content: [
                {
                  text: "关于我们",
                  url: "https://www.baidu.com",
                },
                {
                  text: "市场活动",
                  url: "https://www.baidu.com",
                },
                {
                  text: "公告资讯",
                  url: "https://www.baidu.com",
                },
                {
                  text: "加盟代理",
                  url: "https://www.baidu.com",
                },
                {
                  text: "联系我们",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list4: {
              title: "服务中心",
              content: [
                {
                  text: "售前培训",
                  url: "https://www.baidu.com",
                },
                {
                  text: "帮助文档",
                  url: "https://www.baidu.com",
                },
                {
                  text: "售后反馈",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list3: {
              title: "生产中心",
              content: [
                {
                  text: "山东济宁兖州研发中心",
                  url: "https://www.baidu.com",
                },
                {
                  text: "济宁高新区研发中心",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list2: {
              title: "解决方案",
              content: [
                {
                  text: "售前教程",
                  url: "https://www.baidu.com",
                },
                {
                  text: "培训资料",
                  url: "https://www.baidu.com",
                },
                {
                  text: "教程",
                  url: "https://www.baidu.com",
                },
              ],
            },
          },
          object: {
            img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/bottomBg.png",
            btns: [
              {
                name: "立即加盟",
                url: "https://www.baidu.com",
              },
              {
                name: "售前售后",
                url: "https://www.baidu.com",
              },
            ],
            text: "感谢您对我司品牌的关心与厚爱，全国招募经销商，可咨询 0537-2522017",
            list: [
              {
                text1: "400+",
                text2: "全国销售商",
              },
              {
                text1: "2.0",
                text2: "多种复刻产品",
              },
              {
                text1: "进口",
                text2: "德国原进口品质",
              },
              {
                text1: "100+",
                text2: "售前售后解决方案",
              },
            ],
          },
          height: 708,
        },
        {
          component: "McCard4",
          name: "卡片4",
          icon: "icon-zujian",
          object_list: {
            array: [
              {
                title: "标题1",
                data: "1100",
                switch: true,
              },
              {
                title: "标题2",
                data: "240",
                switch: true,
              },
              {
                title: "标题3",
                data: "8000",
                switch: true,
              },
              {
                title: "标题4",
                data: "900",
                switch: true,
              },
              {
                title: "标题5",
                data: "1100",
                switch: true,
              },
              {
                title: "标题6",
                data: "160",
                switch: true,
              },
            ],
            video:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/shipin1.mp4",
            text1: "用真实数据展示实力",
            text2: "描述文字1",
            text3: "描述文字2",
            text4: "描述文字3",
            text5: "描述文字4",
          },
          id: "1677331912CRZvgT",
          height: 956.5,
        },
      ],
      id: "1649499891FFNR",
      home: false,
    },
    {
      name: "新闻中心",
      componentList: [
        {
          component: "McHeader",
          name: "顶部导航",
          icon: "icon-zujian",
          object: {
            object_bg: {
              color_bg: "#333333",
            },
            object_text: {
              color_text: "#ffffff",
            },
            object_right: {
              string_title: "English",
              jump_right: {},
              color_text: "#664498",
              switch_show: true,
            },
          },
          id: "1672551140AKpT4N",
          height: 68,
        },
        {
          component: "McImg",
          icon: "icon-image",
          name: "图片",
          id: "1650961979DRgs5b",
          imageStyle: {
            cmpUpperPadding: 0,
            cmpLowerPadding: 0,
            pagePadding: 0,
            cmpRadius: 0,
          },
          height: 400.5,
          imageValue: {
            jumpPath: [],
            imagePath:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1655117233303ce.png",
          },
        },
        {
          component: "McCard82",
          name: "卡片82",
          icon: "icon-zujian",
          object: {
            title: "应用场景",
            array: [
              {
                id: 1,
                title: "金融直播",
                text: "理财直播 | 保险课堂 | 在线股评 | 在线投顾 | 投资者教育 | 员工培训",
                iconBlack:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590508869ce.png",
                imgWhite:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590595307ce.png",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673591107721ce.png",
                title1:
                  "直播带货模块可快速对接金融商城，观众可边看直播边买，实现直播带货",
                title2:
                  "直播智能质检，实现直播间聊天信息与画面安全管控，保障直播安全",
                title3:
                  "支持企业组织管理，多分公司、多部门，不同权限，逐级轻松管理",
                title4:
                  "灵活嵌入企业系统/APP/网站/公众号等，用户可随时随地观看直播",
                title5: "",
              },
              {
                id: 2,
                title: "教育直播",
                text: "直播课堂 | 兴趣教育 | 音乐辅导 | 学术讲座 | 职业教育 | 老年大学",
                iconBlack:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590513808ce.png",
                imgWhite:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590610349ce.png",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673591107721ce.png",
                title1:
                  "讲师可使用屏幕共享、文档共享、白板与学员进行互动，师生还可以通过音视频连麦答疑，让学员当堂掌握知识点",
                title2:
                  "直播对艺术（唱歌、乐器）培训行业进行优化，输出原声以及高清画质",
                title3: "直播内容加密、水印、防录屏等，确保课程内容隐私性",
                title4:
                  "支持课程回放转录播，学员可多次回看，直播灵活嵌入APP/小程序/公众号中",
                title5: "",
              },
              {
                id: 3,
                title: "医疗直播",
                text: "医疗培训 | 手术直播 | 多地专家会诊 | 线上知识科普 | 医疗讲座",
                iconBlack:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590522914ce.png",
                imgWhite:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590619180ce.png",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673591107721ce.png",
                title1:
                  "4K超高清无延迟直播画面，真实还原手术场景，支持回放，优秀医疗案例存档学习",
                title2: "支持嘉宾连麦，完成线上多人直播互动，让直播更生动",
                title3:
                  "灵活嵌入企业系统/APP/网站/公众号等，用户可随时随地观看直播",
                title4: "",
                title5: "",
              },
              {
                id: 4,
                title: "企业直播",
                text: "客户培训 | 员工培训 | 产品培训 | 视频会议",
                iconBlack:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590528210ce.png",
                imgWhite:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590584357ce.png",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673591107721ce.png",
                title1:
                  "支持跨海企业同步直播，全球2800多个CDN节点，保障稳定流畅",
                title2:
                  "支持企业组织管理，多分公司、多部门，不同权限，逐级轻松管理",
                title3: "支持连麦，可完成线上多人直播互动，让直播更生动",
                title4: "",
                title5: "",
              },
              {
                id: 5,
                title: "电商直播",
                text: "直播带货 | 理财直播",
                iconBlack:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590532765ce.png",
                imgWhite:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590574791ce.png",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673591107721ce.png",
                title1:
                  "直播带货模块可快速对接金融商城，观众可边看直播边买，实现直播带货",
                title2:
                  "签到、抽奖、点赞、打赏、送礼等多种互动方式，活跃观众气氛",
                title3: "直播间可自定义横幅，广告条等装饰，提前预告，营造氛围",
                title4:
                  "直播结束后，自动完成观众分析，带货分析报表，全方位掌握直播数据",
                title5: "",
              },
              {
                id: 6,
                title: "活动直播",
                text: "即刻通IM+产品发布会 | 行业峰会 | 公司年会 | 展会直播",
                iconBlack:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590537677ce.png",
                imgWhite:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590567906ce.png",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673591107721ce.png",
                title1: "支持千万级用户同时观看，自研音视频更稳定性",
                title2:
                  "签到、抽奖、点赞、打赏、送礼等多种互动方式，活跃观众气氛",
                title3: "与金牌拍摄方深度合作，支持单机位/多机位的拍摄方案",
                title4: "",
                title5: "",
              },
            ],
          },
          id: "1677331944hmexMY",
          height: 840,
        },
        {
          component: "McCard80",
          name: "卡片80",
          icon: "icon-zujian",
          object: {
            title: "产品优势/功能特性",
            array: [
              {
                id: 1,
                title: "灵活部署",
                text: "支持数据传输、录制、存储全过程限制在内网环境，支持政企、金融等高隐私业务场景对数据安全的需求",
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590108758ce.png",
              },
              {
                id: 2,
                title: "全渠道接入",
                text: "网页、QQ、微信公众号、企业微信号、小程序、APP、H5",
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590127368ce.png",
              },
              {
                id: 3,
                title: "统一多媒体工作台",
                text: "实现语音、文字、视频沟通等多种联络方式，实现集中式、分布式、远程移动座席的统一协同管理",
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590122577ce.png",
              },
              {
                id: 4,
                title: "智能算法",
                text: "智能匹配问题，辅助客服接待，智能数字人、智能引导流程设计有效提升效率和专业度",
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590117907ce.png",
              },
              {
                id: 5,
                title: "实时监控",
                text: "多维度监控实时掌握员工工作状态，协助优化资源配置，提高服务效率",
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590133989ce.png",
              },
              {
                id: 6,
                title: "快速升级现有模式",
                text: "兼容企业现有服务与营销模式，提供视频客服、虚拟数字人等服务模块化接入，实现快速升级",
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590113573ce.png",
              },
            ],
          },
          id: "1677331946GDKOtf",
        },
        {
          component: "McCard70",
          name: "卡片70",
          icon: "icon-zujian",
          title: {
            title1: "加入我们",
            title2: "zhaopin01@zego.im",
            title3: "“岗位+姓名”，HR将两天内给答复",
            img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673578727211ce.png",
          },
          id: "1677331939akda2l",
          height: 452,
        },
        {
          component: "McCard64",
          name: "卡片64",
          icon: "icon-zujian",
          object_list: {
            video:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
          },
          id: "16773319367lEK7m",
          height: 760,
        },
        {
          component: "McCard65",
          name: "卡片65",
          icon: "icon-zujian",
          object: {
            title1: "加入我们",
            title2: "一起探索音视频更多可能性",
            title3: "深圳 / 北京 /上海 /武汉 / 成都 /济南",
            button1: {
              text: "校园招聘",
              jump: {},
            },
            button2: {
              text: "社会招聘",
              jump: {},
            },
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673577807509ce.png",
          },
          id: "1677331937CxfyEW",
          height: 760,
        },
        {
          component: "McCard59",
          name: "卡片59",
          icon: "icon-zujian",
          object: {
            title: "软件下载",
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673576022223ce.png",
            way1: {
              text: " 项目合作，留下您的联系方式",
              jump: {},
            },
            way2: {
              text: "索取行业解决方案",
              jump: {},
            },
            button: {
              text: "申请试用",
              jump: {},
            },
          },
          id: "1677331935Kn1wpc",
          height: 430,
        },
        {
          component: "McNewsList",
          icon: "el-icon-menu",
          name: "新闻列表",
          id: "1652332264xq1Duu",
          height: 1251,
        },
        {
          component: "McFooter",
          images: [
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_13.png",
              url: "https://www.baidu.com",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_14.png",
              url: "https://www.baidu.com",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_16.png",
              url: "https://www.baidu.com",
            },
          ],
          bottom: {
            images: [
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_05.png",
                url: "https://www.baidu.com",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_07.png",
                url: "https://www.baidu.com",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_09.png",
                url: "https://www.baidu.com",
              },
            ],
            address: "山东省济宁市北湖新区新城发展大厦B座15楼",
            text1: "欧罗德卡 版权所有",
            text2: "鲁ICP备15123347号",
            icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_15.png",
            logo: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/logo.png",
            time: "2021-2022",
          },
          icon: "el-icon-menu",
          name: "底部版权",
          id: "1652332253BBluSi",
          list: {
            list1: {
              title: "产品",
              content: [
                {
                  text: "机用根管锉",
                  url: "https://www.baidu.com",
                },
                {
                  text: "马达一体机",
                  url: "https://www.baidu.com",
                },
                {
                  text: "无线根管预备机",
                  url: "https://www.baidu.com",
                },
                {
                  text: "手用锉",
                  url: "https://www.baidu.com",
                },
                {
                  text: "Iteeth 3D打印仿真牙",
                  url: "https://www.baidu.com",
                },
                {
                  text: "根管锉消毒盒",
                  url: "https://www.baidu.com",
                },
                {
                  text: "吸潮纸尖",
                  url: "https://www.baidu.com",
                },
                {
                  text: "牙胶尖修整器",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list5: {
              title: "公司",
              content: [
                {
                  text: "关于我们",
                  url: "https://www.baidu.com",
                },
                {
                  text: "市场活动",
                  url: "https://www.baidu.com",
                },
                {
                  text: "公告资讯",
                  url: "https://www.baidu.com",
                },
                {
                  text: "加盟代理",
                  url: "https://www.baidu.com",
                },
                {
                  text: "联系我们",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list4: {
              title: "服务中心",
              content: [
                {
                  text: "售前培训",
                  url: "https://www.baidu.com",
                },
                {
                  text: "帮助文档",
                  url: "https://www.baidu.com",
                },
                {
                  text: "售后反馈",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list3: {
              title: "生产中心",
              content: [
                {
                  text: "山东济宁兖州研发中心",
                  url: "https://www.baidu.com",
                },
                {
                  text: "济宁高新区研发中心",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list2: {
              title: "解决方案",
              content: [
                {
                  text: "售前教程",
                  url: "https://www.baidu.com",
                },
                {
                  text: "培训资料",
                  url: "https://www.baidu.com",
                },
                {
                  text: "教程",
                  url: "https://www.baidu.com",
                },
              ],
            },
          },
          height: 708,
          object: {
            img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/bottomBg.png",
            btns: [
              {
                name: "立即加盟",
                url: "https://www.baidu.com",
              },
              {
                name: "售前售后",
                url: "https://www.baidu.com",
              },
            ],
            text: "感谢您对我司品牌的关心与厚爱，全国招募经销商，可咨询 0537-2522017",
            list: [
              {
                text1: "400+",
                text2: "全国销售商",
              },
              {
                text1: "2.0",
                text2: "多种复刻产品",
              },
              {
                text1: "进口",
                text2: "德国原进口品质",
              },
              {
                text1: "100+",
                text2: "售前售后解决方案",
              },
            ],
          },
        },
      ],
      id: "1649505041gZ4r",
      home: false,
    },
    {
      name: "关于我们",
      componentList: [
        {
          component: "McHeader",
          name: "顶部导航",
          icon: "icon-zujian",
          object: {
            object_bg: {
              color_bg: "#333333",
            },
            object_text: {
              color_text: "#ffffff",
            },
            object_right: {
              string_title: "English",
              jump_right: {},
              color_text: "#664498",
              switch_show: true,
            },
          },
          id: "1672551149u3TMqP",
          height: 68,
        },
        {
          component: "McImg",
          icon: "icon-image",
          name: "图片",
          id: "16522539109EVZxb",
          imageStyle: {
            cmpUpperPadding: 0,
            cmpLowerPadding: 0,
            pagePadding: 0,
            cmpRadius: 0,
          },
          height: 400.5,
          imageValue: {
            jumpPath: [],
            imagePath:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1655117248186ce.png",
          },
        },
        {
          component: "McCard80",
          name: "卡片80",
          icon: "icon-zujian",
          object: {
            title: "产品优势/功能特性",
            array: [
              {
                id: 1,
                title: "灵活部署",
                text: "支持数据传输、录制、存储全过程限制在内网环境，支持政企、金融等高隐私业务场景对数据安全的需求",
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590108758ce.png",
              },
              {
                id: 2,
                title: "全渠道接入",
                text: "网页、QQ、微信公众号、企业微信号、小程序、APP、H5",
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590127368ce.png",
              },
              {
                id: 3,
                title: "统一多媒体工作台",
                text: "实现语音、文字、视频沟通等多种联络方式，实现集中式、分布式、远程移动座席的统一协同管理",
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590122577ce.png",
              },
              {
                id: 4,
                title: "智能算法",
                text: "智能匹配问题，辅助客服接待，智能数字人、智能引导流程设计有效提升效率和专业度",
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590117907ce.png",
              },
              {
                id: 5,
                title: "实时监控",
                text: "多维度监控实时掌握员工工作状态，协助优化资源配置，提高服务效率",
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590133989ce.png",
              },
              {
                id: 6,
                title: "快速升级现有模式",
                text: "兼容企业现有服务与营销模式，提供视频客服、虚拟数字人等服务模块化接入，实现快速升级",
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590113573ce.png",
              },
            ],
          },
          id: "16773319653ijrAM",
          height: 562,
        },
        {
          component: "McCard9",
          name: "卡片9",
          icon: "icon-zujian",
          object: {
            title1: "值得信赖的标题",
            title2: "Mapping Your Business",
            images: [
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
                name: "标题文本1",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei2.png",
                name: "标题文本2",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
                name: "标题文本3",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei2.png",
                name: "标题文本4",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
                name: "标题文本5",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei2.png",
                name: "标题文本6",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
                name: "标题文本7",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei2.png",
                name: "标题文本8",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
                name: "标题文本9",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei2.png",
                name: "标题文本10",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
                name: "标题文本11",
              },
            ],
          },
          id: "1677331969BAi4QL",
          height: 572,
        },
        {
          component: "McCard95",
          name: "卡片95",
          icon: "icon-zujian",
          title: {
            title: "100+ 教育运营工具",
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676616708860ce.png",
          },
          array_list: [
            {
              id: 0,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "店铺设计",
              switch: false,
            },
            {
              id: 1,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "海量模板",
              switch: false,
            },
            {
              id: 2,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "拖拽组件",
              switch: false,
            },
            {
              id: 3,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "多端发布",
              switch: false,
            },
            {
              id: 4,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "信息展示",
              switch: false,
            },
            {
              id: 5,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "报名表单",
              switch: false,
            },
            {
              id: 6,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "在线咨询",
              switch: false,
            },
            {
              id: 7,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "付费会员",
              switch: false,
            },
            {
              id: 8,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "等级会员",
              switch: false,
            },
            {
              id: 9,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "会员权益",
              switch: false,
            },
            {
              id: 10,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "客户管理",
              switch: false,
            },
            {
              id: 11,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "智能名片",
              switch: false,
            },
            {
              id: 12,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "访客列表",
              switch: false,
            },
            {
              id: 13,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "商机雷达",
              switch: false,
            },
            {
              id: 14,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "推广员",
              switch: false,
            },
            {
              id: 15,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "转介绍",
              switch: false,
            },
            {
              id: 16,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "兑换码",
              switch: false,
            },
            {
              id: 17,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "优惠券",
              switch: false,
            },
            {
              id: 18,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "流量主",
              switch: false,
            },
            {
              id: 19,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "400电话",
              switch: false,
            },
            {
              id: 20,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676615104380ce.png",
              text: "模板消息",
              switch: false,
            },
          ],
          id: "1677331973k7hjTQ",
        },
        {
          component: "McCard92",
          name: "卡片92",
          icon: "icon-zujian",
          object: {
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676602997799ce.png",
            title: "马上制作—微信H5店铺",
            button: {
              text: "免费拥有",
              jump: {},
            },
          },
          id: "16773319718S3uXT",
          height: 284,
        },
        {
          component: "McCard8",
          name: "卡片8",
          icon: "icon-zujian",
          object_title: {
            title1: "值得信赖的标题",
            title2: "Mapping Your Business",
          },
          array: [
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              name: "标题文本1",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              name: "标题文本2",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              name: "标题文本3",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              name: "标题文本4",
            },
          ],
          array_list: [
            {
              name: "标题1",
              icon1:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_point_grey.png",
              icon2:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_point_white.png",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              name: "标题2",
              icon1:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_line_grey.png",
              icon2:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_line_white.png",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              name: "标题3",
              icon1:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_surface_grey.png",
              icon2:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_surface_white1.png",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              name: "标题4",
              icon1:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_3D_grey.png",
              icon2:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_3D_white.png",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              name: "标题5",
              icon1:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_heat_grey.png",
              icon2:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_heat_white.png",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
          ],
          id: "1677331968HaY0t8",
          height: 777,
        },
        {
          component: "McCard84",
          name: "卡片84",
          icon: "icon-zujian",
          object: {
            title: "产品优势/功能特性",
            array: [
              {
                id: 1,
                title: "多种方式灵活部署",
                text: "支持私有云、公有云、混合云、海外节点部署等多种部署方式，满足银行机构不同的数据存储和安全需求。",
              },
              {
                id: 2,
                title: "满足全业务直播场景",
                text: "仅需一套产品，就能满足企业在营销、交易、培训、协同办公等全场景应用需求，实现直播业务统一搭建。",
              },
              {
                id: 3,
                title: "灵活定制和对接能力",
                text: "通过aPaaS平台模块组件以及完善、标准的SDK、API接口，快速、低成本地搭建出个性化企业直播平台。",
              },
              {
                id: 4,
                title: "多级权限管理功能",
                text: "支持企业集中管控及分布授权，可以对部门、账户、应用功能、资源包、消耗量、计费金额进行统一管理。",
              },
              {
                id: 5,
                title: "稳定安全保障机制",
                text: "通过多种观看授权功能、异地容灾备份、内容播出安全控制功能等方式，来保证直播平台的安全和可靠。",
              },
              {
                id: 6,
                title: "良好观看性能体验",
                text: "通过高并发支持、低延迟直播、内网观看、多CDN切换、全球实时传输等技术，实现良好的观看体验。",
              },
            ],
          },
          id: "167733196683NFkV",
          height: 656,
        },
        {
          component: "McCard",
          array_image: [
            {
              string_2B: "车企",
              id: "1651908441Y1B46a",
              upload_2X:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              string_2B: "物流地产",
              id: "1651908681vmH0fC",
              upload_2X:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              string_2B: "产业园区",
              id: "1651908695ewR2g5",
              upload_2X:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              string_2B: "制造业",
              id: "1651908712HGI6cI",
              upload_2X:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              string_2B: "政府",
              id: "1651908723ZTH45l",
              upload_2X:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
          ],
          name: "卡片1",
          icon: "icon-zujian",
          object_title: {
            string_title2: "深入行业品牌",
            string_title: "构建标题的核心力量说明",
          },
          id: "1657939411aHArbN",
          height: 761,
        },
        {
          component: "McCard81",
          name: "卡片81",
          icon: "icon-zujian",
          object: {
            title: "智摆优势",
            array: [
              {
                id: 1,
                title: "毫秒级音画延迟",
                text: "覆盖 200+ 国家/地区低延迟直播场景",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590229403ce.png",
              },
              {
                id: 2,
                title: "超高并发极低卡顿",
                text: "支持千万级并发拉流，抗丢包，抗弱网，流畅稳定",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590233823ce.png",
              },
              {
                id: 3,
                title: "弱网高可用",
                text: "自研 QoS 策略，在70%丢包下，仍能稳定流畅播放",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590238798ce.png",
              },
              {
                id: 4,
                title: "自研音视频技术",
                text: "自研底层音视频引擎，具备数十项技术专利",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590307322ce.png",
              },
              {
                id: 5,
                title: "支持私有化定制",
                text: "支持多租户SAAS服务，同时支持私有化混合云部署",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590224281ce.png",
              },
            ],
          },
          id: "1677331962D1s2ck",
          height: 426,
        },
        {
          component: "McAbout",
          icon: "el-icon-menu",
          name: "关于我们",
          id: "1652332328ALhLTg",
          height: 420,
        },
        {
          component: "McOurTeams",
          name: "我们的团队",
          icon: "el-icon-s-custom",
          object_title: {
            title1: "我们的团队",
            title2:
              "我们注重完善的服务体系，提供满意的服务质量，打造优秀的团队。",
          },
          id: "16579394288WaicT",
          height: 349,
        },
        {
          component: "McContact",
          icon: "el-icon-menu",
          name: "联系我们",
          id: "1652332316xKx721",
          height: 736,
        },
        {
          component: "McFooter",
          images: [
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_13.png",
              url: "https://www.baidu.com",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_14.png",
              url: "https://www.baidu.com",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_16.png",
              url: "https://www.baidu.com",
            },
          ],
          bottom: {
            images: [
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_05.png",
                url: "https://www.baidu.com",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_07.png",
                url: "https://www.baidu.com",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_09.png",
                url: "https://www.baidu.com",
              },
            ],
            address: "山东省济宁市北湖新区新城发展大厦B座15楼",
            text1: "欧罗德卡 版权所有",
            text2: "鲁ICP备15123347号",
            icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_15.png",
            logo: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/logo.png",
            time: "2021-2022",
          },
          icon: "el-icon-menu",
          name: "底部版权",
          id: "1652332336rk6FRg",
          list: {
            list1: {
              title: "产品",
              content: [
                {
                  text: "机用根管锉",
                  url: "https://www.baidu.com",
                },
                {
                  text: "马达一体机",
                  url: "https://www.baidu.com",
                },
                {
                  text: "无线根管预备机",
                  url: "https://www.baidu.com",
                },
                {
                  text: "手用锉",
                  url: "https://www.baidu.com",
                },
                {
                  text: "Iteeth 3D打印仿真牙",
                  url: "https://www.baidu.com",
                },
                {
                  text: "根管锉消毒盒",
                  url: "https://www.baidu.com",
                },
                {
                  text: "吸潮纸尖",
                  url: "https://www.baidu.com",
                },
                {
                  text: "牙胶尖修整器",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list5: {
              title: "公司",
              content: [
                {
                  text: "关于我们",
                  url: "https://www.baidu.com",
                },
                {
                  text: "市场活动",
                  url: "https://www.baidu.com",
                },
                {
                  text: "公告资讯",
                  url: "https://www.baidu.com",
                },
                {
                  text: "加盟代理",
                  url: "https://www.baidu.com",
                },
                {
                  text: "联系我们",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list4: {
              title: "服务中心",
              content: [
                {
                  text: "售前培训",
                  url: "https://www.baidu.com",
                },
                {
                  text: "帮助文档",
                  url: "https://www.baidu.com",
                },
                {
                  text: "售后反馈",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list3: {
              title: "生产中心",
              content: [
                {
                  text: "山东济宁兖州研发中心",
                  url: "https://www.baidu.com",
                },
                {
                  text: "济宁高新区研发中心",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list2: {
              title: "解决方案",
              content: [
                {
                  text: "售前教程",
                  url: "https://www.baidu.com",
                },
                {
                  text: "培训资料",
                  url: "https://www.baidu.com",
                },
                {
                  text: "教程",
                  url: "https://www.baidu.com",
                },
              ],
            },
          },
          height: 708,
          object: {
            img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/bottomBg.png",
            btns: [
              {
                name: "立即加盟",
                url: "https://www.baidu.com",
              },
              {
                name: "售前售后",
                url: "https://www.baidu.com",
              },
            ],
            text: "感谢您对我司品牌的关心与厚爱，全国招募经销商，可咨询 0537-2522017",
            list: [
              {
                text1: "400+",
                text2: "全国销售商",
              },
              {
                text1: "2.0",
                text2: "多种复刻产品",
              },
              {
                text1: "进口",
                text2: "德国原进口品质",
              },
              {
                text1: "100+",
                text2: "售前售后解决方案",
              },
            ],
          },
        },
      ],
      id: "1649505050MDQn",
      home: false,
    },
    {
      name: "加盟中心",
      componentList: [
        {
          component: "McHeader",
          name: "顶部导航",
          icon: "icon-zujian",
          object: {
            object_bg: {
              color_bg: "#333333",
            },
            object_text: {
              color_text: "#ffffff",
            },
            object_right: {
              string_title: "English",
              jump_right: {},
              color_text: "#664498",
              switch_show: true,
            },
          },
          id: "1672551157vCBW9R",
          height: 68,
        },
        {
          component: "McImg",
          icon: "icon-image",
          name: "图片",
          id: "1651055777mRfXnu",
          imageStyle: {
            cmpUpperPadding: 0,
            cmpLowerPadding: 0,
            pagePadding: 0,
            cmpRadius: 0,
          },
          height: 400.5,
          imageValue: {
            jumpPath: [],
            imagePath:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1655117261156ce.png",
          },
        },
        {
          component: "McCard96",
          name: "卡片96",
          icon: "icon-zujian",
          array_list: [
            {
              id: 0,
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676603202046ce.png",
              switch: false,
              jump: "",
            },
            {
              id: 1,
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676603209150ce.png",
              switch: false,
              jump: "",
            },
          ],
          id: "1677331989S23Lbl",
        },
        {
          component: "McCard6",
          array_list: [
            {
              string_text: "公司通过ISO13485质量体系认证",
              upload_img:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1653471911261ce.png",
              string_title3: "ISO国际质量体系认证",
            },
            {
              string_text: "产品符合国际标准远销海内外",
              upload_img:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1653471895517ce.png",
              string_title3: "CE欧盟认证",
            },
            {
              string_text: "合资企业 自主研发团队",
              upload_img:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1653471901436ce.png",
              string_title3: "中意合资",
            },
            {
              string_text: "远销北美 欧洲 东南亚等国家",
              upload_img:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1653471906389ce.png",
              string_title3: "79出口国家",
            },
          ],
          icon: "icon-zujian",
          name: "卡片6",
          id: "1652421195aR3igZ",
          object_title: {
            title1: "值得信赖的产品",
            title2: "Europeka trusted products",
          },
          height: 622,
        },
        {
          component: "McCard97",
          name: "卡片97",
          icon: "icon-zujian",
          title: {
            title1: "紧抓微信60+个流量窗口",
            title2: "7亿+用户选择小程序",
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676617901470ce.png",
          },
          array_list: [
            {
              id: 1,
              title: "一扫直达页面",
              text: "轻松获取页面路径链接及二维码",
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676340245756ce.png",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
            },
            {
              id: 2,
              title: "搜索小程序",
              text: "设置页面关键词，占位微信搜索",
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676340245756ce.png",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
            },
            {
              id: 3,
              title: "附近的小程序",
              text: "覆盖5公里内，为线下门店引流",
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676340245756ce.png",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
            },
            {
              id: 4,
              title: "公众号跳转小程序",
              text: "小程序关联公众号，促导流转化",
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676340245756ce.png",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
            },
            {
              id: 5,
              title: "分享小程序",
              text: "自定义分享样式，实现裂变分享",
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676340245756ce.png",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
            },
            {
              id: 6,
              title: "小程序互相跳转",
              text: "实现场景互补、服务延伸",
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676340245756ce.png",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
            },
            {
              id: 7,
              title: "朋友圈广告",
              text: "利用优惠活动高效促进到店消费",
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676340245756ce.png",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
            },
            {
              id: 8,
              title: "最近使用列表",
              text: "提高小程序露出率及用户粘性",
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676340245756ce.png",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
            },
          ],
          id: "1677331988FwnySO",
          height: 720,
        },
        {
          component: "McCard93",
          name: "卡片93",
          icon: "icon-zujian",
          title: {
            title1: "免费拥有微信60+流量入口",
            title2: "坐享熟人社交流量，一站式打造私域方案",
            rightBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676617126099ce.png",
            button: {
              text: "免费拥有",
              jump: {},
            },
          },
          array_list: [
            {
              id: 0,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676614076803ce.png",
              title: "视频号关联",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
              switch: false,
            },
            {
              id: 1,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676614076803ce.png",
              title: "朋友圈广告",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
              switch: false,
            },
            {
              id: 2,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676614076803ce.png",
              title: "扫码直达店铺",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
              switch: false,
            },
            {
              id: 3,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676614076803ce.png",
              title: "微信群推广",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
              switch: false,
            },
            {
              id: 4,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676614085235ce.png",
              title: "微信搜一搜",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
              switch: false,
            },
            {
              id: 5,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676614085235ce.png",
              title: "小程序互相跳转",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
              switch: false,
            },
            {
              id: 6,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676614085235ce.png",
              title: "公众号跳转小程序",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
              switch: false,
            },
            {
              id: 7,
              icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676614085235ce.png",
              title: "分享小程序",
              video:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
              switch: false,
            },
          ],
          id: "16773319860xJmmf",
          height: 924,
        },
        {
          component: "McCard9",
          name: "卡片9",
          icon: "icon-zujian",
          object: {
            title1: "值得信赖的标题",
            title2: "Mapping Your Business",
            images: [
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
                name: "标题文本1",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei2.png",
                name: "标题文本2",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
                name: "标题文本3",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei2.png",
                name: "标题文本4",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
                name: "标题文本5",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei2.png",
                name: "标题文本6",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
                name: "标题文本7",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei2.png",
                name: "标题文本8",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
                name: "标题文本9",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei2.png",
                name: "标题文本10",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
                name: "标题文本11",
              },
            ],
          },
          id: "1677331985QP8ORH",
          height: 572,
        },
        {
          component: "McCard10",
          icon: "icon-zujian",
          name: "卡片10",
          id: "1653532752TnOk8s",
          object: {
            text: "北区负责人: 000000001（刘经理）南区负责人: 000000001（姚经理）市场负责人: 000000001（张经理）",
            title: "联系我们",
          },
          height: 600,
        },
        {
          component: "McJoin",
          icon: "el-icon-menu",
          name: "加盟加入",
          id: "1652332365erxqS2",
          height: 686,
        },
        {
          component: "McFooter",
          images: [
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_13.png",
              url: "https://www.baidu.com",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_14.png",
              url: "https://www.baidu.com",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_16.png",
              url: "https://www.baidu.com",
            },
          ],
          bottom: {
            images: [
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_05.png",
                url: "https://www.baidu.com",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_07.png",
                url: "https://www.baidu.com",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_09.png",
                url: "https://www.baidu.com",
              },
            ],
            address: "山东省济宁市北湖新区新城发展大厦B座15楼",
            text1: "欧罗德卡 版权所有",
            text2: "鲁ICP备15123347号",
            icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_15.png",
            logo: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/logo.png",
            time: "2021-2022",
          },
          icon: "el-icon-menu",
          name: "底部版权",
          id: "1652332369hHdlwE",
          list: {
            list1: {
              title: "产品",
              content: [
                {
                  text: "机用根管锉",
                  url: "https://www.baidu.com",
                },
                {
                  text: "马达一体机",
                  url: "https://www.baidu.com",
                },
                {
                  text: "无线根管预备机",
                  url: "https://www.baidu.com",
                },
                {
                  text: "手用锉",
                  url: "https://www.baidu.com",
                },
                {
                  text: "Iteeth 3D打印仿真牙",
                  url: "https://www.baidu.com",
                },
                {
                  text: "根管锉消毒盒",
                  url: "https://www.baidu.com",
                },
                {
                  text: "吸潮纸尖",
                  url: "https://www.baidu.com",
                },
                {
                  text: "牙胶尖修整器",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list5: {
              title: "公司",
              content: [
                {
                  text: "关于我们",
                  url: "https://www.baidu.com",
                },
                {
                  text: "市场活动",
                  url: "https://www.baidu.com",
                },
                {
                  text: "公告资讯",
                  url: "https://www.baidu.com",
                },
                {
                  text: "加盟代理",
                  url: "https://www.baidu.com",
                },
                {
                  text: "联系我们",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list4: {
              title: "服务中心",
              content: [
                {
                  text: "售前培训",
                  url: "https://www.baidu.com",
                },
                {
                  text: "帮助文档",
                  url: "https://www.baidu.com",
                },
                {
                  text: "售后反馈",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list3: {
              title: "生产中心",
              content: [
                {
                  text: "山东济宁兖州研发中心",
                  url: "https://www.baidu.com",
                },
                {
                  text: "济宁高新区研发中心",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list2: {
              title: "解决方案",
              content: [
                {
                  text: "售前教程",
                  url: "https://www.baidu.com",
                },
                {
                  text: "培训资料",
                  url: "https://www.baidu.com",
                },
                {
                  text: "教程",
                  url: "https://www.baidu.com",
                },
              ],
            },
          },
          height: 708,
          object: {
            img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/bottomBg.png",
            btns: [
              {
                name: "立即加盟",
                url: "https://www.baidu.com",
              },
              {
                name: "售前售后",
                url: "https://www.baidu.com",
              },
            ],
            text: "感谢您对我司品牌的关心与厚爱，全国招募经销商，可咨询 0537-2522017",
            list: [
              {
                text1: "400+",
                text2: "全国销售商",
              },
              {
                text1: "2.0",
                text2: "多种复刻产品",
              },
              {
                text1: "进口",
                text2: "德国原进口品质",
              },
              {
                text1: "100+",
                text2: "售前售后解决方案",
              },
            ],
          },
        },
      ],
      id: "1649558335H3vj",
      home: false,
    },
    {
      name: "售后",
      componentList: [
        {
          component: "McHeader",
          name: "顶部导航",
          icon: "icon-zujian",
          object: {
            object_bg: {
              color_bg: "#333333",
            },
            object_text: {
              color_text: "#ffffff",
            },
            object_right: {
              string_title: "English",
              jump_right: {},
              color_text: "#664498",
              switch_show: true,
            },
          },
          id: "16725511693I1rna",
          height: 68,
        },
        {
          component: "McImg",
          icon: "icon-image",
          name: "图片",
          id: "1650970403GfwoN9",
          imageStyle: {
            cmpUpperPadding: 0,
            cmpLowerPadding: 0,
            pagePadding: 0,
            cmpRadius: 0,
          },
          height: 400.5,
          imageValue: {
            jumpPath: [],
            imagePath:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1655117273307ce.png",
          },
        },
        {
          component: "McCard90",
          name: "卡片90",
          icon: "icon-zujian",
          array_list: [
            {
              id: 0,
              title: "提供丰富H5端网站模板",
              text1: "提供海量知识付费、素质教育、职业技能、考研升学等行业模板",
              text2: "可直接编辑装修模块，自定义内容，节省设计成本",
              text3: "",
              text4: "",
              button: "免费拥有",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676601858308ce.png",
              jump: "",
            },
            {
              id: 1,
              title: "选定模板，直接发布，无需审核",
              text1: "操作灵活，应用便捷，自由度高，多渠道分发",
              text2: "一键生成店铺推广二维码，在线制作店铺分享海报",
              text3: "",
              text4: "",
              button: "免费拥有",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676601866277ce.png",
              jump: "",
            },
            {
              id: 2,
              title: "多种店铺推广传播方式",
              text1:
                "可随时在手机发送好友店铺链接、页面二维码等推广方式，促进店铺曝光，提高品牌认知",
              text2: "",
              text3: "",
              text4: "",
              button: "免费拥有",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676601876348ce.png",
              jump: "",
            },
          ],
          id: "16773320151R9UJc",
        },
        {
          component: "McCard85",
          name: "卡片85",
          icon: "icon-zujian",
          object: {
            title1: "智能云SAAS响应式",
            title2: "建站系统",
            text: "不懂设计、不懂编程，在线制作响应式网站。动动鼠标、想改就改、自由添加、支持深度个性化定制，免费建站。",
            button: "免费建站",
            jump: {},
            video:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/video/cesi.mp4",
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673512757294ce.png",
          },
          id: "16773320139FcstT",
          height: 1150,
        },
        {
          component: "McCard89",
          name: "卡片89",
          icon: "icon-zujian",
          object: {
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676601368064ce.png",
            title: "微信H5网站",
            text: "一键发布，微官网展示丰富课程内容",
            button: {
              text: "免费拥有",
              jump: {},
            },
          },
          id: "1677332009yaEt68",
          height: 560,
        },
        {
          component: "McCard9",
          name: "卡片9",
          icon: "icon-zujian",
          object: {
            title1: "值得信赖的标题",
            title2: "Mapping Your Business",
            images: [
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
                name: "标题文本1",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei2.png",
                name: "标题文本2",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
                name: "标题文本3",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei2.png",
                name: "标题文本4",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
                name: "标题文本5",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei2.png",
                name: "标题文本6",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
                name: "标题文本7",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei2.png",
                name: "标题文本8",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
                name: "标题文本9",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei2.png",
                name: "标题文本10",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
                name: "标题文本11",
              },
            ],
          },
          id: "1677332011LGWrIr",
          height: 572,
        },
        {
          component: "McCard82",
          name: "卡片82",
          icon: "icon-zujian",
          object: {
            title: "应用场景",
            array: [
              {
                id: 1,
                title: "金融直播",
                text: "理财直播 | 保险课堂 | 在线股评 | 在线投顾 | 投资者教育 | 员工培训",
                iconBlack:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590508869ce.png",
                imgWhite:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590595307ce.png",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673591107721ce.png",
                title1:
                  "直播带货模块可快速对接金融商城，观众可边看直播边买，实现直播带货",
                title2:
                  "直播智能质检，实现直播间聊天信息与画面安全管控，保障直播安全",
                title3:
                  "支持企业组织管理，多分公司、多部门，不同权限，逐级轻松管理",
                title4:
                  "灵活嵌入企业系统/APP/网站/公众号等，用户可随时随地观看直播",
                title5: "",
              },
              {
                id: 2,
                title: "教育直播",
                text: "直播课堂 | 兴趣教育 | 音乐辅导 | 学术讲座 | 职业教育 | 老年大学",
                iconBlack:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590513808ce.png",
                imgWhite:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590610349ce.png",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673591107721ce.png",
                title1:
                  "讲师可使用屏幕共享、文档共享、白板与学员进行互动，师生还可以通过音视频连麦答疑，让学员当堂掌握知识点",
                title2:
                  "直播对艺术（唱歌、乐器）培训行业进行优化，输出原声以及高清画质",
                title3: "直播内容加密、水印、防录屏等，确保课程内容隐私性",
                title4:
                  "支持课程回放转录播，学员可多次回看，直播灵活嵌入APP/小程序/公众号中",
                title5: "",
              },
              {
                id: 3,
                title: "医疗直播",
                text: "医疗培训 | 手术直播 | 多地专家会诊 | 线上知识科普 | 医疗讲座",
                iconBlack:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590522914ce.png",
                imgWhite:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590619180ce.png",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673591107721ce.png",
                title1:
                  "4K超高清无延迟直播画面，真实还原手术场景，支持回放，优秀医疗案例存档学习",
                title2: "支持嘉宾连麦，完成线上多人直播互动，让直播更生动",
                title3:
                  "灵活嵌入企业系统/APP/网站/公众号等，用户可随时随地观看直播",
                title4: "",
                title5: "",
              },
              {
                id: 4,
                title: "企业直播",
                text: "客户培训 | 员工培训 | 产品培训 | 视频会议",
                iconBlack:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590528210ce.png",
                imgWhite:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590584357ce.png",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673591107721ce.png",
                title1:
                  "支持跨海企业同步直播，全球2800多个CDN节点，保障稳定流畅",
                title2:
                  "支持企业组织管理，多分公司、多部门，不同权限，逐级轻松管理",
                title3: "支持连麦，可完成线上多人直播互动，让直播更生动",
                title4: "",
                title5: "",
              },
              {
                id: 5,
                title: "电商直播",
                text: "直播带货 | 理财直播",
                iconBlack:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590532765ce.png",
                imgWhite:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590574791ce.png",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673591107721ce.png",
                title1:
                  "直播带货模块可快速对接金融商城，观众可边看直播边买，实现直播带货",
                title2:
                  "签到、抽奖、点赞、打赏、送礼等多种互动方式，活跃观众气氛",
                title3: "直播间可自定义横幅，广告条等装饰，提前预告，营造氛围",
                title4:
                  "直播结束后，自动完成观众分析，带货分析报表，全方位掌握直播数据",
                title5: "",
              },
              {
                id: 6,
                title: "活动直播",
                text: "即刻通IM+产品发布会 | 行业峰会 | 公司年会 | 展会直播",
                iconBlack:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590537677ce.png",
                imgWhite:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590567906ce.png",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673591107721ce.png",
                title1: "支持千万级用户同时观看，自研音视频更稳定性",
                title2:
                  "签到、抽奖、点赞、打赏、送礼等多种互动方式，活跃观众气氛",
                title3: "与金牌拍摄方深度合作，支持单机位/多机位的拍摄方案",
                title4: "",
                title5: "",
              },
            ],
          },
          id: "16773320083d679B",
          height: 840,
        },
        {
          component: "McCard78",
          name: "卡片78",
          icon: "icon-zujian",
          object: {
            title: "产品功能",
            array: [
              {
                id: 1,
                title: "全渠道覆盖，客户无限触达",
                text: "网页、QQ、微信公众号、小程序、APP、H5",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589718439ce.png",
              },
              {
                id: 2,
                title: "接客户喜好接入，按业务需求沟通",
                text: "视频接入、语音接入、文字接入、电话接入、智能AI机器人应答",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589706380ce.png",
              },
              {
                id: 3,
                title: "智能IVR，方便远程协助，提升服务质量",
                text: "音频通话、视频通话、多方视频通话、面签面审、共享屏幕、共享文档、互动白板、电子签名、视频中推送",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589723515ce.png",
              },
              {
                id: 4,
                title: "智能双录，永久存档",
                text: "自动录制、智能双录、多方录制、沟通存档、智能工单、视频打点、知识库管理",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589686812ce.png",
              },
              {
                id: 5,
                title: "智能质检，保障企业客服质量",
                text: "实时监控、大数据智能检查、随机抽查、多类型质检模板、录制回溯",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589697514ce.png",
              },
              {
                id: 6,
                title: "超大客服分组，自定义流程管理",
                text: "根据自身的业务需求配置不同的技能组，配置相关技能组下的客服人员，多种需求同时满足",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589712899ce.png",
              },
              {
                id: 7,
                title: "客户报表一键分析",
                text: "客户管理、客户画像、关键词分析、来源渠道、访问轨迹分析",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589692119ce.png",
              },
              {
                id: 8,
                title: "数字人",
                text: "企业形象定制，多渠道、多场景快速接入",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589734010ce.png",
              },
              {
                id: 9,
                title: "安全防护，保护系统更保护客户",
                text: "恶意防刷保护、客户隐私保密（同一客户自动分配给原客服），防信息窃取、阻攻击行为",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589728524ce.png",
              },
              {
                id: 10,
                title: "客服质量统计分析",
                text: "人工客服坐席工作量、工单量、服务质量、客户满意度",
                switch: true,
                icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673589701730ce.png",
              },
            ],
          },
          id: "167733200158jddj",
          height: 998,
        },
        {
          component: "McFeedback",
          icon: "el-icon-menu",
          name: "售后反馈",
          id: "1652332402NZX2ER",
          height: 712,
        },
        {
          component: "McFooter",
          images: [
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_13.png",
              url: "https://www.baidu.com",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_14.png",
              url: "https://www.baidu.com",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_16.png",
              url: "https://www.baidu.com",
            },
          ],
          bottom: {
            images: [
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_05.png",
                url: "https://www.baidu.com",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_07.png",
                url: "https://www.baidu.com",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_09.png",
                url: "https://www.baidu.com",
              },
            ],
            address: "山东省济宁市北湖新区新城发展大厦B座15楼",
            text1: "欧罗德卡 版权所有",
            text2: "鲁ICP备15123347号",
            icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_15.png",
            logo: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/logo.png",
            time: "2021-2022",
          },
          icon: "el-icon-menu",
          name: "底部版权",
          id: "1652332399yyiRen",
          list: {
            list1: {
              title: "产品",
              content: [
                {
                  text: "机用根管锉",
                  url: "https://www.baidu.com",
                },
                {
                  text: "马达一体机",
                  url: "https://www.baidu.com",
                },
                {
                  text: "无线根管预备机",
                  url: "https://www.baidu.com",
                },
                {
                  text: "手用锉",
                  url: "https://www.baidu.com",
                },
                {
                  text: "Iteeth 3D打印仿真牙",
                  url: "https://www.baidu.com",
                },
                {
                  text: "根管锉消毒盒",
                  url: "https://www.baidu.com",
                },
                {
                  text: "吸潮纸尖",
                  url: "https://www.baidu.com",
                },
                {
                  text: "牙胶尖修整器",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list5: {
              title: "公司",
              content: [
                {
                  text: "关于我们",
                  url: "https://www.baidu.com",
                },
                {
                  text: "市场活动",
                  url: "https://www.baidu.com",
                },
                {
                  text: "公告资讯",
                  url: "https://www.baidu.com",
                },
                {
                  text: "加盟代理",
                  url: "https://www.baidu.com",
                },
                {
                  text: "联系我们",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list4: {
              title: "服务中心",
              content: [
                {
                  text: "售前培训",
                  url: "https://www.baidu.com",
                },
                {
                  text: "帮助文档",
                  url: "https://www.baidu.com",
                },
                {
                  text: "售后反馈",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list3: {
              title: "生产中心",
              content: [
                {
                  text: "山东济宁兖州研发中心",
                  url: "https://www.baidu.com",
                },
                {
                  text: "济宁高新区研发中心",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list2: {
              title: "解决方案",
              content: [
                {
                  text: "售前教程",
                  url: "https://www.baidu.com",
                },
                {
                  text: "培训资料",
                  url: "https://www.baidu.com",
                },
                {
                  text: "教程",
                  url: "https://www.baidu.com",
                },
              ],
            },
          },
          height: 708,
          object: {
            img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/bottomBg.png",
            btns: [
              {
                name: "立即加盟",
                url: "https://www.baidu.com",
              },
              {
                name: "售前售后",
                url: "https://www.baidu.com",
              },
            ],
            text: "感谢您对我司品牌的关心与厚爱，全国招募经销商，可咨询 0537-2522017",
            list: [
              {
                text1: "400+",
                text2: "全国销售商",
              },
              {
                text1: "2.0",
                text2: "多种复刻产品",
              },
              {
                text1: "进口",
                text2: "德国原进口品质",
              },
              {
                text1: "100+",
                text2: "售前售后解决方案",
              },
            ],
          },
        },
      ],
      id: "16495584549CtV",
      home: false,
    },
    {
      name: "售前",
      componentList: [
        {
          component: "McHeader",
          name: "顶部导航",
          icon: "icon-zujian",
          object: {
            object_bg: {
              color_bg: "#333333",
            },
            object_text: {
              color_text: "#ffffff",
            },
            object_right: {
              string_title: "English",
              jump_right: {},
              color_text: "#664498",
              switch_show: true,
            },
          },
          id: "1672551264cfBsyW",
        },
        {
          component: "McImg",
          icon: "icon-image",
          name: "图片",
          id: "1652251449dg6o0W",
          imageStyle: {
            cmpUpperPadding: 0,
            cmpLowerPadding: 0,
            pagePadding: 0,
            cmpRadius: 0,
          },
          height: 452.5,
          imageValue: {
            jumpPath: [],
            imagePath:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1655117281651ce.png",
          },
        },
        {
          component: "McHelpCenter",
          icon: "el-icon-menu",
          name: "帮助中心",
          id: "1652332458XPXmnl",
          height: 449,
        },
        {
          component: "McHelp",
          icon: "el-icon-menu",
          name: "售前",
          id: "16523324910fgSD3",
          height: 876,
        },
        {
          component: "McFooter",
          images: [
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_13.png",
              url: "https://www.baidu.com",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_14.png",
              url: "https://www.baidu.com",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_16.png",
              url: "https://www.baidu.com",
            },
          ],
          bottom: {
            images: [
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_05.png",
                url: "https://www.baidu.com",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_07.png",
                url: "https://www.baidu.com",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_09.png",
                url: "https://www.baidu.com",
              },
            ],
            address: "山东省济宁市北湖新区新城发展大厦B座15楼",
            text1: "欧罗德卡 版权所有",
            text2: "鲁ICP备15123347号",
            icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_15.png",
            logo: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/logo.png",
            time: "2021-2022",
          },
          icon: "el-icon-menu",
          name: "底部版权",
          id: "1652332495CW3CGr",
          list: {
            list1: {
              title: "产品",
              content: [
                {
                  text: "机用根管锉",
                  url: "https://www.baidu.com",
                },
                {
                  text: "马达一体机",
                  url: "https://www.baidu.com",
                },
                {
                  text: "无线根管预备机",
                  url: "https://www.baidu.com",
                },
                {
                  text: "手用锉",
                  url: "https://www.baidu.com",
                },
                {
                  text: "Iteeth 3D打印仿真牙",
                  url: "https://www.baidu.com",
                },
                {
                  text: "根管锉消毒盒",
                  url: "https://www.baidu.com",
                },
                {
                  text: "吸潮纸尖",
                  url: "https://www.baidu.com",
                },
                {
                  text: "牙胶尖修整器",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list5: {
              title: "公司",
              content: [
                {
                  text: "关于我们",
                  url: "https://www.baidu.com",
                },
                {
                  text: "市场活动",
                  url: "https://www.baidu.com",
                },
                {
                  text: "公告资讯",
                  url: "https://www.baidu.com",
                },
                {
                  text: "加盟代理",
                  url: "https://www.baidu.com",
                },
                {
                  text: "联系我们",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list4: {
              title: "服务中心",
              content: [
                {
                  text: "售前培训",
                  url: "https://www.baidu.com",
                },
                {
                  text: "帮助文档",
                  url: "https://www.baidu.com",
                },
                {
                  text: "售后反馈",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list3: {
              title: "生产中心",
              content: [
                {
                  text: "山东济宁兖州研发中心",
                  url: "https://www.baidu.com",
                },
                {
                  text: "济宁高新区研发中心",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list2: {
              title: "解决方案",
              content: [
                {
                  text: "售前教程",
                  url: "https://www.baidu.com",
                },
                {
                  text: "培训资料",
                  url: "https://www.baidu.com",
                },
                {
                  text: "教程",
                  url: "https://www.baidu.com",
                },
              ],
            },
          },
          height: 893,
          object: {
            img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/bottomBg.png",
            btns: [
              {
                name: "立即加盟",
                url: "https://www.baidu.com",
              },
              {
                name: "售前售后",
                url: "https://www.baidu.com",
              },
            ],
            text: "感谢您对我司品牌的关心与厚爱，全国招募经销商，可咨询 0537-2522017",
            list: [
              {
                text1: "400+",
                text2: "全国销售商",
              },
              {
                text1: "2.0",
                text2: "多种复刻产品",
              },
              {
                text1: "进口",
                text2: "德国原进口品质",
              },
              {
                text1: "100+",
                text2: "售前售后解决方案",
              },
            ],
          },
        },
      ],
      id: "1649576074v3XE",
      home: false,
    },
    {
      name: "培训中心",
      componentList: [
        {
          component: "McHeader",
          name: "顶部导航",
          icon: "icon-zujian",
          object: {
            object_bg: {
              color_bg: "#333333",
            },
            object_text: {
              color_text: "#ffffff",
            },
            object_right: {
              string_title: "English",
              jump_right: {},
              color_text: "#664498",
              switch_show: true,
            },
          },
          id: "1672551271Hq9U6D",
          height: 68,
        },
        {
          component: "McImg",
          icon: "icon-image",
          name: "图片",
          id: "16524211583wYOH9",
          imageStyle: {
            cmpUpperPadding: 0,
            cmpLowerPadding: 0,
            pagePadding: 0,
            cmpRadius: 0,
          },
          imageValue: {
            jumpPath: [],
            imagePath:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1652421167341ce.png",
          },
          height: 456.5,
        },
        {
          component: "McCard71",
          name: "卡片71",
          icon: "icon-zujian",
          object: {
            title1: "和我们一起，构建实时互动的世界",
            title2: "超4000家企业用户，百余种场景解决方案",
            button: "加入我们",
            jump: {},
            array: [
              {
                id: 1,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673580924466ce.png",
              },
              {
                id: 2,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673580937438ce.png",
              },
              {
                id: 3,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673580943567ce.png",
              },
              {
                id: 4,
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673580950767ce.png",
              },
            ],
          },
          id: "1677332037lcjYxU",
          height: 540,
        },
        {
          component: "McCard74",
          name: "卡片74",
          icon: "icon-zujian",
          object: {
            title: "为全球 4000+ 企业客户提供服务",
            imgs1: {
              switch: true,
              array: [
                {
                  id: 1,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582136495ce.png",
                },
                {
                  id: 2,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582155730ce.png",
                },
                {
                  id: 3,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582162663ce.png",
                },
                {
                  id: 4,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582168116ce.png",
                },
                {
                  id: 5,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582174721ce.png",
                },
                {
                  id: 6,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582179749ce.png",
                },
                {
                  id: 7,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582189346ce.png",
                },
                {
                  id: 8,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582213033ce.png",
                },
              ],
            },
            imgs2: {
              switch: true,
              array: [
                {
                  id: 1,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582198561ce.png",
                },
                {
                  id: 2,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582205632ce.png",
                },
                {
                  id: 3,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582380282ce.png",
                },
                {
                  id: 4,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582390119ce.png",
                },
                {
                  id: 5,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582395820ce.png",
                },
                {
                  id: 6,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582420819ce.png",
                },
                {
                  id: 7,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582409579ce.png",
                },
                {
                  id: 8,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582427439ce.png",
                },
              ],
            },
            imgs3: {
              switch: true,
              array: [
                {
                  id: 1,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582136495ce.png",
                },
                {
                  id: 2,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582205632ce.png",
                },
                {
                  id: 3,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582380282ce.png",
                },
                {
                  id: 4,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582390119ce.png",
                },
                {
                  id: 5,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582174721ce.png",
                },
                {
                  id: 6,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582179749ce.png",
                },
                {
                  id: 7,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582189346ce.png",
                },
                {
                  id: 8,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582427439ce.png",
                },
              ],
            },
            imgs4: {
              switch: true,
              array: [
                {
                  id: 1,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582198561ce.png",
                },
                {
                  id: 2,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582155730ce.png",
                },
                {
                  id: 3,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582162663ce.png",
                },
                {
                  id: 4,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582168116ce.png",
                },
                {
                  id: 5,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582395820ce.png",
                },
                {
                  id: 6,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582420819ce.png",
                },
                {
                  id: 7,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582409579ce.png",
                },
                {
                  id: 8,
                  img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673582213033ce.png",
                },
              ],
            },
          },
          id: "16773320392UOc1R",
        },
        {
          component: "McCard15",
          array: [
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
              content: "文本介绍内容处描述文本介绍内容处描述",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
              content: "文本介绍内容处描述文本介绍内容处描述",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
              content: "文本介绍内容处描述文本介绍内容处描述",
            },
          ],
          name: "卡片15",
          icon: "icon-zujian",
          id: "1657939484JFZFt8",
          title: {
            title1: "值得信赖的标题",
            title2: "Mapping Your Business",
          },
          height: 611,
        },
        {
          component: "McTrainCenter",
          icon: "el-icon-s-order",
          name: "培训中心",
          id: "1652421156XPvuZF",
          height: 213,
        },
        {
          component: "McFooter",
          images: [
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_13.png",
              url: "https://www.baidu.com",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_14.png",
              url: "https://www.baidu.com",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_16.png",
              url: "https://www.baidu.com",
            },
          ],
          bottom: {
            images: [
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_05.png",
                url: "https://www.baidu.com",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_07.png",
                url: "https://www.baidu.com",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_09.png",
                url: "https://www.baidu.com",
              },
            ],
            address: "山东省济宁市北湖新区新城发展大厦B座15楼",
            text1: "欧罗德卡 版权所有",
            text2: "鲁ICP备15123347号",
            icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_15.png",
            logo: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/logo.png",
            time: "2021-2022",
          },
          icon: "el-icon-s-opportunity",
          name: "底部版权",
          id: "1652421149xmL2qE",
          list: {
            list1: {
              title: "产品",
              content: [
                {
                  text: "机用根管锉",
                  url: "https://www.baidu.com",
                },
                {
                  text: "马达一体机",
                  url: "https://www.baidu.com",
                },
                {
                  text: "无线根管预备机",
                  url: "https://www.baidu.com",
                },
                {
                  text: "手用锉",
                  url: "https://www.baidu.com",
                },
                {
                  text: "Iteeth 3D打印仿真牙",
                  url: "https://www.baidu.com",
                },
                {
                  text: "根管锉消毒盒",
                  url: "https://www.baidu.com",
                },
                {
                  text: "吸潮纸尖",
                  url: "https://www.baidu.com",
                },
                {
                  text: "牙胶尖修整器",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list5: {
              title: "公司",
              content: [
                {
                  text: "关于我们",
                  url: "https://www.baidu.com",
                },
                {
                  text: "市场活动",
                  url: "https://www.baidu.com",
                },
                {
                  text: "公告资讯",
                  url: "https://www.baidu.com",
                },
                {
                  text: "加盟代理",
                  url: "https://www.baidu.com",
                },
                {
                  text: "联系我们",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list4: {
              title: "服务中心",
              content: [
                {
                  text: "售前培训",
                  url: "https://www.baidu.com",
                },
                {
                  text: "帮助文档",
                  url: "https://www.baidu.com",
                },
                {
                  text: "售后反馈",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list3: {
              title: "生产中心",
              content: [
                {
                  text: "山东济宁兖州研发中心",
                  url: "https://www.baidu.com",
                },
                {
                  text: "济宁高新区研发中心",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list2: {
              title: "解决方案",
              content: [
                {
                  text: "售前教程",
                  url: "https://www.baidu.com",
                },
                {
                  text: "培训资料",
                  url: "https://www.baidu.com",
                },
                {
                  text: "教程",
                  url: "https://www.baidu.com",
                },
              ],
            },
          },
          height: 708,
          object: {
            img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/bottomBg.png",
            btns: [
              {
                name: "立即加盟",
                url: "https://www.baidu.com",
              },
              {
                name: "售前售后",
                url: "https://www.baidu.com",
              },
            ],
            text: "感谢您对我司品牌的关心与厚爱，全国招募经销商，可咨询 0537-2522017",
            list: [
              {
                text1: "400+",
                text2: "全国销售商",
              },
              {
                text1: "2.0",
                text2: "多种复刻产品",
              },
              {
                text1: "进口",
                text2: "德国原进口品质",
              },
              {
                text1: "100+",
                text2: "售前售后解决方案",
              },
            ],
          },
        },
      ],
      id: "165018847192gN",
      home: false,
    },
    {
      name: "市场活动",
      componentList: [
        {
          component: "McHeader",
          name: "顶部导航",
          icon: "icon-zujian",
          object: {
            object_bg: {
              color_bg: "#333333",
            },
            object_text: {
              color_text: "#ffffff",
            },
            object_right: {
              string_title: "English",
              jump_right: {},
              color_text: "#664498",
              switch_show: true,
            },
          },
          id: "1672551279RW6oBI",
          height: 68,
        },
        {
          component: "McCard89",
          name: "卡片89",
          icon: "icon-zujian",
          object: {
            imgBg:
              "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1676601368064ce.png",
            title: "微信H5网站",
            text: "一键发布，微官网展示丰富课程内容",
            button: {
              text: "免费拥有",
              jump: {},
            },
          },
          id: "1677332051Ud7D72",
        },
        {
          component: "McCard12",
          array: [
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              title: "标题标题",
            },
          ],
          name: "卡片12",
          icon: "icon-zujian",
          object_title: {
            title1: "值得信赖的标题",
            title2: "Mapping Your Busin",
          },
          id: "1657939462xcW6C1",
          height: 429,
        },
        {
          component: "McCard82",
          name: "卡片82",
          icon: "icon-zujian",
          object: {
            title: "应用场景",
            array: [
              {
                id: 1,
                title: "金融直播",
                text: "理财直播 | 保险课堂 | 在线股评 | 在线投顾 | 投资者教育 | 员工培训",
                iconBlack:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590508869ce.png",
                imgWhite:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590595307ce.png",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673591107721ce.png",
                title1:
                  "直播带货模块可快速对接金融商城，观众可边看直播边买，实现直播带货",
                title2:
                  "直播智能质检，实现直播间聊天信息与画面安全管控，保障直播安全",
                title3:
                  "支持企业组织管理，多分公司、多部门，不同权限，逐级轻松管理",
                title4:
                  "灵活嵌入企业系统/APP/网站/公众号等，用户可随时随地观看直播",
                title5: "",
              },
              {
                id: 2,
                title: "教育直播",
                text: "直播课堂 | 兴趣教育 | 音乐辅导 | 学术讲座 | 职业教育 | 老年大学",
                iconBlack:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590513808ce.png",
                imgWhite:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590610349ce.png",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673591107721ce.png",
                title1:
                  "讲师可使用屏幕共享、文档共享、白板与学员进行互动，师生还可以通过音视频连麦答疑，让学员当堂掌握知识点",
                title2:
                  "直播对艺术（唱歌、乐器）培训行业进行优化，输出原声以及高清画质",
                title3: "直播内容加密、水印、防录屏等，确保课程内容隐私性",
                title4:
                  "支持课程回放转录播，学员可多次回看，直播灵活嵌入APP/小程序/公众号中",
                title5: "",
              },
              {
                id: 3,
                title: "医疗直播",
                text: "医疗培训 | 手术直播 | 多地专家会诊 | 线上知识科普 | 医疗讲座",
                iconBlack:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590522914ce.png",
                imgWhite:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590619180ce.png",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673591107721ce.png",
                title1:
                  "4K超高清无延迟直播画面，真实还原手术场景，支持回放，优秀医疗案例存档学习",
                title2: "支持嘉宾连麦，完成线上多人直播互动，让直播更生动",
                title3:
                  "灵活嵌入企业系统/APP/网站/公众号等，用户可随时随地观看直播",
                title4: "",
                title5: "",
              },
              {
                id: 4,
                title: "企业直播",
                text: "客户培训 | 员工培训 | 产品培训 | 视频会议",
                iconBlack:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590528210ce.png",
                imgWhite:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590584357ce.png",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673591107721ce.png",
                title1:
                  "支持跨海企业同步直播，全球2800多个CDN节点，保障稳定流畅",
                title2:
                  "支持企业组织管理，多分公司、多部门，不同权限，逐级轻松管理",
                title3: "支持连麦，可完成线上多人直播互动，让直播更生动",
                title4: "",
                title5: "",
              },
              {
                id: 5,
                title: "电商直播",
                text: "直播带货 | 理财直播",
                iconBlack:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590532765ce.png",
                imgWhite:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590574791ce.png",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673591107721ce.png",
                title1:
                  "直播带货模块可快速对接金融商城，观众可边看直播边买，实现直播带货",
                title2:
                  "签到、抽奖、点赞、打赏、送礼等多种互动方式，活跃观众气氛",
                title3: "直播间可自定义横幅，广告条等装饰，提前预告，营造氛围",
                title4:
                  "直播结束后，自动完成观众分析，带货分析报表，全方位掌握直播数据",
                title5: "",
              },
              {
                id: 6,
                title: "活动直播",
                text: "即刻通IM+产品发布会 | 行业峰会 | 公司年会 | 展会直播",
                iconBlack:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590537677ce.png",
                imgWhite:
                  "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673590567906ce.png",
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673591107721ce.png",
                title1: "支持千万级用户同时观看，自研音视频更稳定性",
                title2:
                  "签到、抽奖、点赞、打赏、送礼等多种互动方式，活跃观众气氛",
                title3: "与金牌拍摄方深度合作，支持单机位/多机位的拍摄方案",
                title4: "",
                title5: "",
              },
            ],
          },
          id: "1677332049LqTQJF",
          height: 840,
        },
        {
          component: "McCard8",
          name: "卡片8",
          icon: "icon-zujian",
          object_title: {
            title1: "值得信赖的标题",
            title2: "Mapping Your Business",
          },
          array: [
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              name: "标题文本1",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              name: "标题文本2",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              name: "标题文本3",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
              name: "标题文本4",
            },
          ],
          array_list: [
            {
              name: "标题1",
              icon1:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_point_grey.png",
              icon2:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_point_white.png",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              name: "标题2",
              icon1:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_line_grey.png",
              icon2:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_line_white.png",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              name: "标题3",
              icon1:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_surface_grey.png",
              icon2:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_surface_white1.png",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              name: "标题4",
              icon1:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_3D_grey.png",
              icon2:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_3D_white.png",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
            {
              name: "标题5",
              icon1:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_heat_grey.png",
              icon2:
                "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/introduction/data_icon_heat_white.png",
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/zhanwei.png",
            },
          ],
          id: "1677332047GbyFxO",
          height: 777,
        },
        {
          component: "McOurTeams",
          icon: "el-icon-s-custom",
          name: "我们的团队",
          id: "1653297445yh8o9Y",
          object_title: {
            title1: "市场活动",
            title2:
              "我们注重完善的服务体系，提供满意的服务质量，打造优秀的团队。",
          },
          height: 349,
        },
        {
          component: "McFooter",
          images: [
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_13.png",
              url: "https://www.baidu.com",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_14.png",
              url: "https://www.baidu.com",
            },
            {
              img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_16.png",
              url: "https://www.baidu.com",
            },
          ],
          bottom: {
            images: [
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_05.png",
                url: "https://www.baidu.com",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_07.png",
                url: "https://www.baidu.com",
              },
              {
                img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_09.png",
                url: "https://www.baidu.com",
              },
            ],
            address: "山东省济宁市北湖新区新城发展大厦B座15楼",
            text1: "欧罗德卡 版权所有",
            text2: "鲁ICP备15123347号",
            icon: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/11_15.png",
            logo: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/logo.png",
            time: "2021-2022",
          },
          icon: "el-icon-menu",
          name: "底部版权",
          id: "1652332336rk6FRg",
          list: {
            list1: {
              title: "产品",
              content: [
                {
                  text: "机用根管锉",
                  url: "https://www.baidu.com",
                },
                {
                  text: "马达一体机",
                  url: "https://www.baidu.com",
                },
                {
                  text: "无线根管预备机",
                  url: "https://www.baidu.com",
                },
                {
                  text: "手用锉",
                  url: "https://www.baidu.com",
                },
                {
                  text: "Iteeth 3D打印仿真牙",
                  url: "https://www.baidu.com",
                },
                {
                  text: "根管锉消毒盒",
                  url: "https://www.baidu.com",
                },
                {
                  text: "吸潮纸尖",
                  url: "https://www.baidu.com",
                },
                {
                  text: "牙胶尖修整器",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list5: {
              title: "公司",
              content: [
                {
                  text: "关于我们",
                  url: "https://www.baidu.com",
                },
                {
                  text: "市场活动",
                  url: "https://www.baidu.com",
                },
                {
                  text: "公告资讯",
                  url: "https://www.baidu.com",
                },
                {
                  text: "加盟代理",
                  url: "https://www.baidu.com",
                },
                {
                  text: "联系我们",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list4: {
              title: "服务中心",
              content: [
                {
                  text: "售前培训",
                  url: "https://www.baidu.com",
                },
                {
                  text: "帮助文档",
                  url: "https://www.baidu.com",
                },
                {
                  text: "售后反馈",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list3: {
              title: "生产中心",
              content: [
                {
                  text: "山东济宁兖州研发中心",
                  url: "https://www.baidu.com",
                },
                {
                  text: "济宁高新区研发中心",
                  url: "https://www.baidu.com",
                },
              ],
            },
            list2: {
              title: "解决方案",
              content: [
                {
                  text: "售前教程",
                  url: "https://www.baidu.com",
                },
                {
                  text: "培训资料",
                  url: "https://www.baidu.com",
                },
                {
                  text: "教程",
                  url: "https://www.baidu.com",
                },
              ],
            },
          },
          height: 708,
          object: {
            img: "https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibaiqiye/static/image/index/bottomBg.png",
            btns: [
              {
                name: "立即加盟",
                url: "https://www.baidu.com",
              },
              {
                name: "售前售后",
                url: "https://www.baidu.com",
              },
            ],
            text: "感谢您对我司品牌的关心与厚爱，全国招募经销商，可咨询 0537-2522017",
            list: [
              {
                text1: "400+",
                text2: "全国销售商",
              },
              {
                text1: "2.0",
                text2: "多种复刻产品",
              },
              {
                text1: "进口",
                text2: "德国原进口品质",
              },
              {
                text1: "100+",
                text2: "售前售后解决方案",
              },
            ],
          },
        },
      ],
      id: "1653470307HZHT",
      home: false,
    },
  ],
};
