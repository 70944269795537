<!--
 * @Description: What's this for
 * @Autor: zhangzhang
 * @Date: 2021-09-24 09:11:38
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-05-19 16:20:53
-->
<template>
  <config-item :label="label">
    <Imgpond v-model="mValue" v-bind="mOptions" :count="1" />
  </config-item>
</template>

<script>
import http from '@/utils/cloud';
import schemaMixin from '@/mixin/schemaMixin';

export default {
  name: 'SchemaUpload',
  mixins: [schemaMixin],
  data() {
    return {
      mValue: '',
    };
  },
  methods: {
    async imgSuccess(base64) {
      let result = await this.$aliOSS(base64);
      if (result) {
        console.log(result.url);
        this.mValue = result.url;
      } else {
        this.$message.error('图片失败');
        this.mValue = '';
      }

      // const res = await http.POST('/jw-admin', {
      //   module: 'file',
      //   operation: 'addFile',
      //   base64: base64,
      // });
      // if (res) {
      //   mValue = res.fileID;
      // } else {
      //   this.$message.error('图片失败');
      //   mValue = '';
      // }
    },
  },
};
</script>
